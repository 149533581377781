import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from "axios";
import store from "../store/index";

import { pascalizeKeys } from "humps";
import { AppSettings } from "../assets/constants";

import vue from "vue";
import { MsalHelper } from "../lib/msalPlugin";

export default class HttpClient {
  ControllerName: string;

  private constants = new AppSettings();
  private msalHelper = new MsalHelper();
  private apiHttpClient: AxiosInstance;
  private _retry = false;

  constructor(controller: string) {
    this.ControllerName = controller;

    // eslint-disable-next-line

    let token = "";
    try {
      token = store.getters.user.Accesstoken;
      if (!token) {
        const authResp = this.msalHelper.SilentLogon().then((response) => {
          if (response) {
            token = response.accessToken;
            store.dispatch("UserLoggedIn", response);
            this.apiHttpClient = this.createAxiosClient(token);
          }
        });
      }
    } catch (error: any) {
      console.log(error);
      throw new Error("User not logged in");
    }

    this.apiHttpClient = this.createAxiosClient(token);
  }

  private createAxiosClient(token: string): AxiosInstance {
    const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;
    // eslint-disable-next-line
    function isIsoDateString(value: any): boolean {
      return value && typeof value === "string" && isoDateFormat.test(value);
    }

    function handleDates(body: any) {
      if (body === null || body === undefined || typeof body !== "object") return body;

      for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) {
          body[key] = new Date(value);
        } else if (typeof value === "object") handleDates(value);
      }
    }

    const instance = axios.create({
      baseURL: `${this.constants.ApiAddress}api/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    });

    instance.interceptors.response.use((originalResponse) => {
      handleDates(originalResponse.data);
      return originalResponse;
    });

    instance.interceptors.response.use((response: AxiosResponse) => {
      if (response.data && response.headers["content-type"].includes("application/json")) {
        response.data = pascalizeKeys(response.data);
      }
      return response;
    });

    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError) => {
        const originalRequest: any = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const response = await this.msalHelper.SilentLogon();
          if (response && originalRequest !== undefined) {
            originalRequest.headers["Authorization"] = `bearer ${response.accessToken}`;
            store.dispatch("UserLoggedIn", response);
            return axios(originalRequest);
          }
        }
      }
    );

    return instance;
  }

  private async executeFetch<T>(
    path: string,
    method: Method,
    Actionid?: string | undefined,
    queryParameters?: string | undefined
  ): Promise<T> {
    let requestUrl = "";

    if (!path.startsWith("https://")) {
      requestUrl += `${this.ControllerName}/${path}`;

      if (Actionid) {
        requestUrl += `/${Actionid}`;
      }

      if (queryParameters) {
        requestUrl += "?" + queryParameters;
      }
    } else {
      requestUrl = path;
    }

    const response = await this.apiHttpClient.request<T>({
      method: method,
      url: requestUrl,
    });

    return response.data;
  }

  private async executeSubmit<T, TRV>(path: string, method: Method, data: T): Promise<AxiosResponse<TRV>> {
    try {
      return await this.apiHttpClient.request<TRV>({
        method: method,
        url: `${this.ControllerName}/${path}`,
        data: JSON.stringify(data),
      });
    } catch (error: any) {
      try {
        return {
          status: error.response.status,
          data: error.response.data,
          statusText: error.response.statusText,
          headers: error.response.headers,
          config: error.response.config,
        };
      } catch (error: any) {
        return {
          status: 500,
          data: error.response,
          statusText: "",
          headers: error.response.headers,
          config: error.response.config,
        };
      }
    }
  }

  async Upload(path: string, data: FormData): Promise<AxiosResponse<void>> {
    try {
      return await this.apiHttpClient.post(`${this.ControllerName}/${path}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error: any) {
      try {
        return {
          status: error.response.status,
          data: error.response.data,
          statusText: error.response.statusText,
          headers: error.response.headers,
          config: error.response.config,
        };
      } catch (error: any) {
        return {
          status: 500,
          data: error.response,
          statusText: "",
          headers: error.response.headers,
          config: error.response.config,
        };
      }
    }
  }

  async Post<T, TRV>(path: string, data: T): Promise<AxiosResponse<TRV>> {
    return await this.executeSubmit(path, "POST", data);
  }

  async Get<T>(path: string, id?: string | undefined, queryParameters?: string | undefined): Promise<T> {
    return (await this.executeFetch(path, "GET", id, queryParameters)) as T;
  }

  async Delete<T>(path: string, id: string, queryParameters?: string | undefined): Promise<T> {
    return (await this.executeFetch(path, "DELETE", id, queryParameters)) as T;
  }

  async Put<T, TRV>(path: string, data: T | null): Promise<AxiosResponse<TRV>> {
    return await this.executeSubmit(path, "PUT", data);
  }
}
