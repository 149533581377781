import { IdToken } from "@azure/msal-common";
import Vue from "vue";
import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import User from "../Models/User";
//https://www.cnblogs.com/Answer1215/p/10784316.html

Vue.use(Vuex);

class State {
  userContext = new User();
}

const getters = <GetterTree<State, State>>{
  user: (state): User => state.userContext,
  isAuthenticated: (state) => state.userContext.isAuthenticated,
};

const actions = <ActionTree<State, any>>{
  UserLoggedIn(context, obj: any) {
    const user = new User();
    user.Name = obj.idTokenClaims.name;
    user.Username = obj.idTokenClaims.preferred_username;
    user.Idtoken = obj.idToken;
    user.Accesstoken = obj.accessToken;
    user.isAuthenticated = true;
    user.roles = obj.idTokenClaims.roles;
    this.commit("SET_USER", user);
  },
};

const mutations = <MutationTree<State>>{
  SET_USER(state: State, payload: User) {
    state.userContext = payload;
  },
};

export default new Vuex.Store({
  state: new State(),
  mutations: mutations,
  getters: getters,
  actions: actions,
  modules: {},
});
