
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

import MemberService from "../services/MemberService";
import FileInformation from "../Models/FileInformation";
import FileService from "../services/FileService";
import UploadContainer from "./UploadContainer.vue";

@Component({
  components: { UploadContainer },
})
export default class MemberAndLotFilesCard extends Vue {
  @Prop() private PersonId!: string;
  @Prop() private LotId!: string;

  private memberService = new MemberService();
  private fileService = new FileService();

  host = `${vue.prototype.$Constants.ApiAddress}api`;

  Filename(type: string, childName: string): string {
    let url = type + "/";
    if (type === "Tuin") url += this.LotId;
    else url += this.PersonId;

    url += "/";

    url += childName;

    return url;
  }

  private async FilesUploaded(): Promise<void> {
    console.log("Files uploaded");
    await this.UpdateFolders();
  }

  private OpenInNewTarget(TypeOfFile: string, item: string) {
    window.open(`${this.host}/file/${item}`);
  }

  private folders = [
    {
      active: false,
      action: "mdi-folder",
      items: [{ title: "Test", icon: "mdi-file", fileLink: "" }],
      title: "Tuin",
    },
    {
      active: false,
      action: "mdi-folder",
      items: [{ title: "Test", icon: "mdi-file", fileLink: "" }],
      title: "Lid",
    },
  ];

  private files = new Array<FileInformation>();

  formatDate(date: Date | null): string | null {
    if (date === null) return null;
    return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }

  async UpdateFolders(): Promise<void> {
    var lotFiles = await this.fileService.GetLotFiles(this.LotId);
    if (lotFiles !== null) {
      this.folders[0].items.length = 0;
      lotFiles.forEach((file) => {
        this.folders[0].items.push({ title: file.FileName, icon: "mdi-file", fileLink: file.FileLink });
      });
    }

    var memberFiles = await this.fileService.GetMemberFiles(this.PersonId);

    if (memberFiles !== null) {
      this.folders[1].items.length = 0;
      memberFiles.forEach((file) => {
        this.folders[1].items.push({ title: file.FileName, icon: "mdi-file", fileLink: file.FileLink });
      });
    }
  }

  async RemoveFile(FileToRemove: string): Promise<void> {
    await this.fileService.RemoveFile(`${this.host}/file/${FileToRemove}`, this.PersonId);
    await this.UpdateFolders();
  }

  async mounted(): Promise<void> {
    await this.UpdateFolders();
  }
}
