import { LedgerResult } from "./LedgerResult";
import { ReportRow } from "./ReportRow";

export class LedgerState {
  constructor(init?: Partial<LedgerState>) {
    Object.assign(this, init);
  }
  LastUpdate!: Date;

  LedgerList = new Array<ReportRow>();

  LedgerCreditResult = new Array<LedgerResult>();
  LedgerDebetResult = new Array<LedgerResult>();
}
