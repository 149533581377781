
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

import VerkochtArtikel from "./VerkochtArtikel.vue";

import SalesService from "../services/SalesService";
import { WeekOmzetRegel } from "../Models/Plu";

@Component({ components: { VerkochtArtikel } })
export default class VerkochteArtikelen extends Vue {
  @Prop() private WeekNummer!: number;
  @Prop() private SalesState!: number;

  private salesService: SalesService | null = null;
  private SearchCrit = "";
  private IsReadOnlyArticle = false;

  private OmzetRegels = new Array<WeekOmzetRegel>();

  @Watch("SalesState")
  onPropertyChanged(newvalue: number): void {
    if (newvalue === 2) {
      this.IsReadOnlyArticle = true;
      this.SaveChanges();
    }
    if (newvalue === 1) this.IsReadOnlyArticle = false;
  }

  @Watch("WeekNummer")
  Onchange(): void {
    this.$msal.SilentLogon().then((response) => {
      this.$store.dispatch("UserLoggedIn", response);
      this.GetSupplyList();
    });
  }

  formatDate(date: Date | null): string | null {
    if (date === null) return null;
    return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }

  formatAmount(value: number): string {
    const formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  UpdateTotal(a: unknown, b: unknown, c: unknown): void {
    if (typeof a === "undefined" || typeof b === "undefined" || typeof c === "undefined") return;
    var i = this.OmzetRegels.find((e) => e.PluNummer === a) as WeekOmzetRegel;
    if (!i) return;
    i.Aantal = (c as number) ?? 0;
    i.Totaal = (b as number) ?? 0;
  }

  private SaveChanges(): void {
    var itemsToSave = this.OmzetRegels.filter((e: WeekOmzetRegel) => e.Aantal > 0);
    this.salesService.SaveSoldItems(itemsToSave);
  }

  get WeekTotaal(): number {
    var resp = this.OmzetRegels.reduce((partial_sum, a) => partial_sum + (isNaN(a.Totaal) ? 9 : a.Totaal), 0);
    if (isNaN(resp)) {
      return 0;
    }
    return resp;
  }

  async GetSupplyList(): Promise<void> {
    if (!this.salesService) this.salesService = new SalesService();

    let PLUList = await this.salesService.GetPlus();
    let CurrentItems = await this.salesService.GetSoldItems(new Date().getFullYear(), this.WeekNummer);

    this.OmzetRegels = new Array<WeekOmzetRegel>();

    PLUList.sort(function (a, b) {
      return a.PluNummer - b.PluNummer;
    });

    for (let index = 0; index < PLUList.length; index++) {
      var s = PLUList[index];
      let obj = CurrentItems.find((e) => e.Plu.PluNummer == s.PluNummer) ?? new WeekOmzetRegel();
      if (!obj.Id) {
        obj.WeekNummer = this.WeekNummer;
        obj.Jaar = new Date().getFullYear();
        obj.Id = uuidv4();
        obj.PluNummer = s.PluNummer;
        obj.PluId = s.Id;
        obj.PluNaam = s.Name;
        obj.Prijs = s.VerkoopPrijs;
      } else {
        obj.PluNummer = obj.Plu.PluNummer;
        obj.PluNaam = obj.Plu.Name;
        obj.Totaal = obj.Aantal * obj.Prijs;
      }

      this.OmzetRegels.push(obj);
    }
  }

  async mounted(): Promise<void> {
    this.salesService = new SalesService();
    // await this.GetSupplyList();
  }
}
