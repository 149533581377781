
import vue from "vue";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

import MemberService from "../services/MemberService";
import PosArticle from "../Models/PosArticle";

@Component
export default class PosItem extends Vue {
  @Prop() private posArticle!: PosArticle;
  @Prop() private ArticleTab!: number;
  private memberService = new MemberService();

  @Emit()
  QuantityChanged(): boolean {
    return true;
  }

  get ItemColor(): string {
    switch (this.posArticle.ArticleTab) {
      case 0:
        return "green";
      case 1:
        return "yellow";
      case 2:
        return "blue";
      default:
        return "red";
    }
  }

  get IsVisible(): boolean {
    return this.posArticle.ArticleTab == this.ArticleTab;
  }

  private ChangeQuantity(amount: number) {
    this.posArticle.Quantity += amount;
    this.$emit("quantity-changed", true);
  }
}
