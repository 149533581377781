
import { Component, Watch, Vue } from "vue-property-decorator";

import PluCard from "../components/PluCard.vue";

@Component({
  components: { PluCard },
})
export default class Plu extends Vue {
  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }
}
