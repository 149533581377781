import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Lot from "../views/Lot.vue";
import Members from "../views/Members.vue";
import Bookkeeping from "../views/Bookkeeping.vue";
import ImportBankStatements from "../views/ImportBankStatements.vue";
import RegisterLedgerTransactions from "../views/RegisterLedgerTransactions.vue";
import WaterMeasurements from "../views/WaterMeasurements.vue";
import ADashboard from "../views/ADashboard.vue";
import InvoicePreferenceView from "../views/InvoicePreferenceView.vue";
import House from "../views/House.vue";
import Plu from "../views/Plu.vue";
import store from "../store/index";
import SalesRegistry from "../views/SalesRegistry.vue";
import WaterMeasurementRegistration from "../views/WaterMeasurementRegistration.vue";
import CashRegister from "../views/CashRegister.vue";
import RegisterHouseOffering from "../views/RegisterHouseOffering.vue";
import Inspection from "../views/Inspection.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { icon: "home" },
  },

  {
    path: "/FactuurVoorkeur",
    name: "Factuur voorkeur",
    component: InvoicePreferenceView,
    meta: { icon: "view-dashboard", Authorized: false, hidden: true },
  },
  {
    path: "/Kaart",
    name: "Kaart",
    component: ADashboard,
    meta: { icon: "view-dashboard", Authorized: true, hidden: true },
  },
  {
    path: "/Overzicht",
    name: "Kavels/tuinen",
    meta: { icon: "ruler-square", Authorized: true, Role: "Member.CRUD" },
    component: Lot,
  },
  {
    path: "/Members",
    name: "Leden",
    meta: { icon: "account", Authorized: true, hidden: true, Role: "Member.CRUD" },
    component: Members,
  },
  {
    path: "/House",
    name: "Huisjes",
    meta: { icon: "home-edit", Authorized: true, hidden: false, Role: "Member.CRUD" },
    component: House,
  },
  {
    path: "/Bookkeeping",
    name: "Boekhouding",
    meta: { icon: "cash", hidden: false, Authorized: true, Role: "Bookkeeping.Read" },
    component: Bookkeeping,
  },
  {
    path: "/Meterstanden",
    name: "Meterstanden",
    meta: { icon: "water", Authorized: true, hidden: true },
    component: WaterMeasurements,
  },
  {
    path: "/RegisterLedgerTransactions",
    name: "RegisterLedgerTransactions",
    meta: { icon: "cash", hidden: true, Authorized: true, Role: "Bookkeeping.Read" },
    component: RegisterLedgerTransactions,
    props: { default: true },
  },
  {
    path: "/ImportBankStatements",
    name: "ImportBankStatements",
    meta: { icon: "cash", hidden: true, Authorized: true, Role: "Bookkeeping.CRUD" },
    component: ImportBankStatements,
  },
  {
    path: "/POS",
    name: "Pos",
    meta: { icon: "cash-register", hidden: false, Authorized: true, Role: "POS.CRU" },
    component: CashRegister,
  },
  {
    path: "/VerkoopRegistratie",
    name: "VerkoopRegistratie",
    meta: { icon: "cash-100", hidden: false, Authorized: true, Role: "CashRegister.CRU" },
    component: SalesRegistry,
  },

  {
    path: "/ReactieOpHuisje",
    name: "ReactieOpHuisje",
    meta: { icon: "cash-100", hidden: true, Authorized: false },
    component: RegisterHouseOffering,
  },
  {
    path: "/Plu",
    name: "Plu",
    meta: { icon: "palette-swatch-variant", hidden: false, Authorized: true, Role: "Plu.CRU" },
    component: Plu,
  },
  {
    path: "/WaterMeasurementRegistration",
    name: "WaterMeasurementRegistration",
    meta: { icon: "palette-swatch-variant", hidden: true, Authorized: false },
    component: WaterMeasurementRegistration,
  },
  {
    path: "/Schouwen",
    name: "Inspection",
    meta: { icon: "book-search", hidden: true, Authorized: false },
    component: Inspection,
  },
  {
    path: "/about",
    name: "About",
    meta: { icon: "information-outline" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  { path: "*", component: Home, meta: { hidden: true } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
