import { Plu, WeekOmzetRegel } from "../Models/Plu";
import PosArticle from "../Models/PosArticle";
import { SalesSheet } from "../Models/SalesSheet";
import { v4 as uuidv4 } from "uuid";

import HttpClient from "./HttpClient";

export default class SalesService {
  httpClient = new HttpClient("Sales");

  private posArticles!: Array<PosArticle>;

  async GetPlus(): Promise<Array<Plu>> {
    return await this.httpClient.Get("PLU");
  }

  async SavePlu(item: Plu): Promise<void> {
    await this.httpClient.Post("Plu", item);
  }

  async SaveSoldItems(itemsToSave: WeekOmzetRegel[] | undefined): Promise<void> {
    if (!itemsToSave) return;

    await this.httpClient.Post("WeekArtikelen", itemsToSave);
  }

  async GetSoldItems(Jaar: number, Week: number): Promise<Array<WeekOmzetRegel>> {
    const response = await this.httpClient.Get<Array<WeekOmzetRegel>>(`WeekArtikelen?jaar=${Jaar}&week=${Week}`);
    return response;
  }

  async GetPosGroups(): Promise<void> {
    const items = await this.httpClient.Get<Array<PosArticle>>(`PosGroups`);

    items.forEach((e) => {
      e.GroupArticleId = e.Id;
      e.Quantity = 0;
    });

    this.posArticles = items;
  }

  FillMissingSalesArticles(sheet: SalesSheet): void {
    this.posArticles.forEach((e) => {
      if (sheet.SoldPosGroupItems.findIndex((v) => v.GroupArticleId == e.GroupArticleId) > -1) {
        return;
      }
      const t = { ...e };
      t.Id = uuidv4();
      sheet.SoldPosGroupItems.push(t);
    });
  }

  async SalesSheetPayed(id: string, paymendMethod: string): Promise<void> {
    await this.httpClient.Post(`SalesSheetPayed/${id}`, paymendMethod);
  }

  async getSalesSheets(): Promise<Array<SalesSheet>> {
    await this.GetPosGroups();
    const salesSheets = await this.httpClient.Get<Promise<Array<SalesSheet>>>("SalesSheets");
    salesSheets.forEach((s) => {
      this.AmendSalesSheet(s);

      const orderedItems = s.SoldPosGroupItems.sort(function (a, b) {
        return a.OrderId - b.OrderId;
      });

      s.SoldPosGroupItems = orderedItems;
    });

    return salesSheets;
  }

  async GetOrCreateSalesSheet(Name: string): Promise<SalesSheet | null> {
    const salesSheet = await this.httpClient.Get<Promise<SalesSheet | string>>("SalesSheet", Name);
    if (salesSheet == "") {
      const newSalesSheet = new SalesSheet({ Name: Name });
      this.FillMissingSalesArticles(newSalesSheet);
      return newSalesSheet;
    }

    const sheet = salesSheet as SalesSheet;
    await this.AmendSalesSheet(sheet);

    const orderedItems = sheet.SoldPosGroupItems.sort(function (a, b) {
      return a.OrderId - b.OrderId;
    });

    sheet.SoldPosGroupItems = orderedItems;
    return sheet;
  }

  private async AmendSalesSheet(salessheet: SalesSheet): Promise<void> {
    salessheet.SoldPosGroupItems.forEach((element: PosArticle) => {
      const groupItem = this.posArticles.filter((e) => e.GroupArticleId == element.GroupArticleId)[0];
      element.Name = groupItem.Name;
      element.Price = groupItem.Price;
      element.OrderId = groupItem.OrderId;
      element.IsADrink = groupItem.IsADrink;
      element.GroupArticleId = groupItem.GroupArticleId;
      element.Category = groupItem.Category;
      element.TaxGroup = groupItem.TaxGroup;
      element.ArticleTab = groupItem.ArticleTab;
    });
    this.FillMissingSalesArticles(salessheet);
  }

  async SaveSheet(selectedSheet: SalesSheet): Promise<void> {
    await this.httpClient.Post("SalesSheet", selectedSheet);
  }
}
