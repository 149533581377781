
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

import { Plu } from "../Models/Plu";

import SalesService from "../services/SalesService";

@Component
export default class PluCard extends Vue {
  search = "";
  sortBy = "PluNummer";
  itemsPerPage = 100;

  private salesService = new SalesService();

  private menuVisibility = false;
  private dialog = false;
  private dialogDelete = false;
  private headers = [
    {
      text: "Plu nummer",
      align: "start",
      sortable: true,
      value: "PluNummer",
      width: "100px",
    },
    { text: "Omschrijving", value: "Name" },
    { text: "Inkoopprijs", value: "InkoopPrijs", align: "right" },
    { text: "Verkoopprijs", value: "VerkoopPrijs", align: "right" },
    { text: "Geldig van", value: "Start" },
    { text: "Geldig tot", value: "End" },
    { text: "Actions", value: "actions", sortable: false },
  ];
  private PluItems = new Array<Plu>();
  private editedIndex = -1;
  private editedItem = new Plu();
  private defaultItem = new Plu();

  formTitle(): string {
    return this.editedIndex === -1 ? "Nieuw Artikel" : "Aanpassen Artikel";
  }

  parseDate(value: string): Date | string | null {
    var dateParts = value.split("-") as Array<any>;

    if (isNaN(dateParts[0]) || isNaN(dateParts[1]) || isNaN(dateParts[2])) return value;
    if (dateParts[2].length !== 4) return value;

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    return dateObject;
  }

  formatDate(date: Date | string | null): string {
    try {
      if (date === null) return "";
      if (!(date instanceof Date)) return date;

      return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
    } catch (error) {
      return "";
    }
  }

  formatAmount(value: number): string {
    const formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  private editItem(item: Plu) {
    this.editedIndex = this.PluItems.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  private deleteItem(item: Plu) {
    this.editedIndex = this.PluItems.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  private async deleteItemConfirm(): Promise<void> {
    this.PluItems.splice(this.editedIndex, 1);
    this.closeDelete();

    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.editedItem.End = d;
    await this.salesService.SavePlu(this.editedItem);
  }

  private close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  private async save(): Promise<void> {
    if (this.editedItem.End === "") {
      this.editedItem.End = null;
    }
    if (this.editedIndex > -1) {
      Object.assign(this.PluItems[this.editedIndex], this.editedItem);
    } else {
      this.editedItem.Id = uuidv4();
      this.PluItems.push(this.editedItem);
    }
    await this.salesService.SavePlu(this.editedItem);
    this.close();
  }

  async mounted(): Promise<void> {
    const response = await this.salesService.GetPlus();
    this.PluItems = response;
  }
}
