import { AxiosResponse } from "axios";
import { BankStatement } from "../Models/BankStatement";
import { BankStatementLedgerAssignment } from "../Models/BankStatementLedgerAssignment";
import { Ledger } from "../Models/Ledger";
import { LedgerResult } from "../Models/LedgerResult";

import HttpClient from "./HttpClient";

export default class BookkeepingService {
  httpClient = new HttpClient("Bookkeeping");

  async UploadLedgers(formData: FormData): Promise<AxiosResponse<void>> {
    return await this.httpClient.Upload("SubmitBankStatements", formData);
  }

  async AvailableBookingYears(): Promise<number[]> {
    const response = await this.httpClient.Get<Array<number>>("GetAvailableBookYears");

    return response;
  }

  async FetchCurrentState(BookYear: number): Promise<Array<LedgerResult> | null> {
    const response = await this.httpClient.Get<Array<LedgerResult>>("GetCurrentJournalSummary", BookYear.toString());
    return response;
  }

  async LastImportDate(): Promise<Date> {
    const response = await this.httpClient.Get<string>("GetLastImportDate");

    return new Date(Date.parse(response));
  }

  async GetUnassignedBankstatements(): Promise<Array<BankStatementLedgerAssignment>> {
    const response = await this.httpClient.Get<Array<BankStatementLedgerAssignment>>("GetUnAssignedLedgers");

    response.forEach((element) => {
      element.RemarkHover = false;
    });
    return response;
  }

  async BankStatementsLedgerAssignmentsForLedger(
    LedgerCode: string,
    bookYear: number
  ): Promise<Array<BankStatementLedgerAssignment>> {
    const response = await this.httpClient.Get<Array<BankStatementLedgerAssignment>>(
      "BankStatementLedgerAssignments",
      LedgerCode,
      `Year=${bookYear}`
    );
    response.forEach((element) => {
      element.RemarkHover = false;
    });
    return response;
  }

  async Ledgers(): Promise<Array<Ledger>> {
    const response = await this.httpClient.Get<Array<Ledger>>("Ledgers");
    const spaces = "                                                                                            ";
    response.forEach((element) => {
      element.CodePresentation = spaces.substr(0, element.Code.length * 3);
    });
    return response;
  }

  async UpdateBankStatement(assignment: BankStatementLedgerAssignment): Promise<void> {
    const bankstatement: BankStatement = {
      Id: assignment.Id,
      LedgerId: assignment.BankStatementLedgerID,
      Description: assignment.Description,
    };

    await this.httpClient.Put("UpdateBankStatementLedger", bankstatement);
  }
}
