import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { MsalPluginFactory } from "./lib/msalPlugin";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { ConstantFactory, AppSettings } from "./assets/constants";
import "./sass/main.scss";
import VueCookies from "vue-cookies";
//https://github.com/marcino239/vue-msal-webapi/blob/main/client/src/App.vue

//https://cli.vuejs.org/guide/mode-and-env.html#modes

Vue.config.productionTip = false;
const appSettings = new AppSettings();

Vue.use(ConstantFactory, appSettings);
Vue.use(VueCookies);
Vue.use(MsalPluginFactory);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
