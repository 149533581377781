
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { InspectionFinding } from "../Models/InspectionFinding";
import LotService from "../services/lotService";
import { v4 as uuidv4 } from "uuid";
import InspectionFindingItem from "./InspectionFindingItem.vue";

@Component({
  components: { InspectionFindingItem },
})
export default class InspectionFindingCard extends Vue {
  private lotService = new LotService();
  private IsLoading = false;

  @Prop() private LotId!: string;

  private inspectionFindings: Array<InspectionFinding> = [
    {
      Id: null,
      Answers: [],
      Description: "",
      ActionToBeTaken: "",
      DueDate: "",
      MemberAgreed: false,
      MemberInformed: false,
      ReferenceDate: "",
      LotId: "",
    },
  ];

  formatDateTime(date: Date | null): string | null {
    if (date === null) return null;

    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }

  AddNewFinding(): void {
    var date = new Date();
    var newInspectionFinding: InspectionFinding = {
      ReferenceDate: this.formatDateTime(date),
      DueDate: this.formatDateTime(new Date(date.setMonth(date.getMonth() + 6))),
      Description: "",
      ActionToBeTaken: "",
      MemberAgreed: false,
      Id: uuidv4(),
      LotId: this.LotId,
      MemberInformed: true,
      Answers: [],
    };

    this.inspectionFindings = [newInspectionFinding].concat(this.inspectionFindings);
  }

  async mounted(): Promise<void> {
    const response = await this.lotService.GetInspectionFindings(this.LotId);

    this.inspectionFindings = response;
    return;
  }
}
