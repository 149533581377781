import WaterMeasurement from "../Models/WaterMeasurement";
import HttpClient from "./HttpClient";

export default class WaterMeasurementService {
  private httpclient: HttpClient;
  constructor() {
    this.httpclient = new HttpClient("WaterMeasurements");
  }

  async Upsert(item: WaterMeasurement): Promise<void> {
    await this.httpclient.Post("Register", item);
  }

  async Get(): Promise<Array<WaterMeasurement>> {
    const items = await this.httpclient.Get<Array<WaterMeasurement>>("RegisteredWaterMeasurements");
    return items;
  }
  async GetMeasurementForLot(measurement: WaterMeasurement): Promise<number | null> {
    const previousMeasurement = await this.httpclient.Get<number | null>(
      `PreviousMeasurementForLot/${measurement.LotNumber}`
    );
    return previousMeasurement;
  }

  async GetCurrentMeasurementForLot(measurement: WaterMeasurement): Promise<number | null> {
    const previousMeasurement = await this.httpclient.Get<number | null>(
      `CurrentMeasurementForLot/${measurement.LotNumber}`
    );
    return previousMeasurement;
  }
}
