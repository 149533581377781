import { v4 as uuidv4 } from "uuid";
import PosArticle from "./PosArticle";

export class SalesSheet {
  constructor(init?: Partial<SalesSheet>) {
    Object.assign(this, init);
  }

  Id: string = uuidv4();
  Name = "";
  Memberid = "";
  SoldPosGroupItems = new Array<PosArticle>();
  StaticSheet = false;
  CanBuyOnCredit = false;
  GiftCardValue = 0;
  PaymentMethod = "";
  WhoIsPaying = "";
}
