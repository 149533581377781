
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { AuthenticationResult } from "@azure/msal-common";
import { Component, Watch, Vue } from "vue-property-decorator";
import { AppSettings } from "./assets/constants";
import User from "./Models/User";
import MemberService from "./services/MemberService";

@Component
export default class MainApp extends Vue {
  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  @Watch("IsAuthenticated")
  OnChanged(): void {
    this.SetPages();
  }

  private drawer: Array<string> | null = null;
  private messages = 4;
  private showUpgradeUI = false;
  private memberService = new MemberService();
  private LotNumber = "";

  size(): Record<string, boolean> {
    const size = { xs: "x-small", sm: "small", md: "medium", lg: "large", xl: "x-large" }[
      this.$vuetify.breakpoint.name
    ];
    return size ? { [size]: true } : {};
  }

  private Pages: Array<{ name?: string; path?: string; icon?: string }> = [];

  private SetPages(): void {
    this.Pages = new Array<{ name?: string; path?: string; icon?: string }>();
    this.$router.getRoutes().forEach((route) => {
      if (!route.meta.hidden && this.IsInRole(route.meta.Role)) {
        this.Pages.push({
          name: route.name,
          icon: route.meta.icon ?? "circle-medium",
          path: route.path === "" ? "/" : route.path,
        });
      }
    });
  }

  private GoToLotOnEnter(e: KeyboardEvent): void {
    if (e.key === "Enter") {
      this.GoToLot();
    }
  }

  private GoToLot(): void {
    this.memberService.GetIdsForLotNumber(this.LotNumber).then((x) => {
      if (x == null) return;
      // this.$router
      //   .push({
      //     name: "Kaart",
      //     params: { MemberId: x.Item2, LotNumber: this.LotNumber, LotId: x.Item1 },
      //   })
      //   .catch();
    });
  }

  IsInRole(role: string | undefined): boolean {
    if (!role) return true;
    const roles = (this.$store.getters.user as User).roles;

    if (roles == null) return false;
    if (roles.findIndex((e) => e == "Admin") >= 0) return true;
    const rr = roles?.findIndex((e) => e == role) >= 0;
    return rr;
  }

  async InitiateContext(): Promise<void> {
    const resp = await this.$msal.SilentLogon();
    if (resp == null) {
      return;
    }
    this.processLogin(resp);
  }

  async mounted(): Promise<void> {
    await this.InitiateContext();
    this.SetPages();
  }

  logout(): void {
    this.$msal.logoutRedirect();
  }

  private processLogin(x: AuthenticationResult) {
    this.$store.dispatch("UserLoggedIn", x);
  }

  login(): void {
    this.$msal
      .login()
      .then((x: AuthenticationResult) => {
        this.processLogin(x);
      })
      .catch((x: Error) => {
        console.log("error", x);
      });
  }
}
