
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Vue, Watch } from "vue-property-decorator";

import PersonCard from "../components/PersonCard.vue";
import BankAccountCard from "../components/BankAccountCard.vue";
import InvoiceCard from "../components/InvoiceCard.vue";
import WaterMeasurementCard from "../components/WaterMeasurementsCard.vue";
import MemberAndLotFilesCard from "../components/MemberAndLotFilesCard.vue";
import InspectionFindingCard from "../components/InspectionFindingCard.vue";
import MemberInformation from "../components/MemberInformation.vue";

import LotCard from "../components/LotCard.vue";

import MemberService from "../services/MemberService";
import LotService from "../services/lotService";
import { MemberOverview } from "../Models/MemberOverview";
import User from "../Models/User";
import { v4 as uuidv4 } from "uuid";

@Component({
  components: {
    PersonCard,
    BankAccountCard,
    InvoiceCard,
    WaterMeasurementCard,
    MemberAndLotFilesCard,
    LotCard,
    InspectionFindingCard,
    MemberInformation,
  },
})
export default class ADashboard extends Vue {
  private MemberId: string | null = null;
  private LotNumber: string | null = null;
  private LotId: string | null = null;
  private ForSale = false;
  private SelectedMemberForOwnerShip: MemberOverview | null = null;
  private PartnerIdOfMember: string | null = null;

  private MembersWithoutLot = new Array<MemberOverview>();

  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  async RegisterPartner(personId: string): Promise<void> {
    const memberService = new MemberService();
    var partnerid = await memberService.CreatePartnerMember(personId);
    this.PartnerIdOfMember = partnerid;
  }

  IsInRole(role: string): boolean {
    const user: User = this.$store.getters.user;
    return this.$msal.IsInRole(user, role);
  }

  private TopFunction() {
    console.log("Top function");
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  async MarkLotAsAvailable(): Promise<void> {
    const lotService = new LotService();
    lotService.MarkLotForSale(this.LotId as string);
    this.ForSale = true;
    await this.SetMembersWithoutLot();
  }

  async ResetLotAvailablitiy(): Promise<void> {
    const lotService = new LotService();
    lotService.ResetLotAvailablitiy(this.LotId as string);
    this.ForSale = false;
  }

  async ReAssignLot(): Promise<void> {
    if (this.SelectedMemberForOwnerShip === null) return;
    const lotService = new LotService();
    await lotService.AssignNewMember(this.SelectedMemberForOwnerShip.MemberId, this.LotId as string);
  }

  async SetMembersWithoutLot(): Promise<void> {
    const memberService = new MemberService();
    this.MembersWithoutLot = await memberService.GetMembers(false);
  }

  BackToMemberList(): void {
    console.log(`${typeof this.LotId !== "object"}`);
    this.$router.push({ name: "Kavels/tuinen", params: { LotMembers: `${typeof this.LotId !== "object"}` } });
  }

  async mounted(): Promise<void> {
    this.MemberId = this.$route.params.MemberId;
    this.LotNumber = this.$route.params.LotNumber;
    this.LotId = this.$route.params.LotId;

    const lotnr: string | null = this.$route.query["LotNr"] as string | null;

    if (!lotnr && !this.MemberId) {
      this.$router.push("/Overzicht");
      return;
    }

    if (!this.IsAuthenticated) {
      try {
        var response = await this.$msal.SilentLogon();
        this.$store.dispatch("UserLoggedIn", response);
      } catch (error) {
        this.$router.push("/home");
      }
    }

    const memberService = new MemberService();
    const lotService = new LotService();

    async function Resolve(LotNumber: string): Promise<{ MemberId: string; LotId: string }> {
      return await lotService.GetLotIds(LotNumber);
    }

    if (lotnr && !this.MemberId) {
      let IdsToResolve: { MemberId: string; LotId: string };

      IdsToResolve = await Resolve(lotnr as string);

      this.MemberId = IdsToResolve.MemberId;
      this.LotNumber = lotnr;
      this.LotId = IdsToResolve.LotId;
    }

    this.ForSale = await lotService.IsForSale(this.LotId);
    if (this.ForSale) {
      await this.SetMembersWithoutLot();
    }
    var PartnerId = await memberService.GetPartnerIdOfMember(this.MemberId);
    if (PartnerId !== null) this.PartnerIdOfMember = PartnerId;
  }
}
