
import { Component, Vue } from "vue-property-decorator";

import BookkeepingService from "../services/Bookkeepingservice";

@Component
export default class ImportBankStatements extends Vue {
  bookkeepingService = new BookkeepingService();

  IsLoading = false;
  FileSelected = false;
  IsProcessed = false;
  InError = false;
  currentFile!: File;
  FileRule = [(v: string): boolean => v.endsWith(".csv")];

  selectFile(file: File): void {
    if (!file.name.endsWith(".csv")) {
      this.FileSelected = false;
      return;
    }
    this.currentFile = file;
    this.FileSelected = true;
    this.InError = false;
  }

  async UploadFile(): Promise<void> {
    this.IsLoading = true;
    var formData = new FormData();
    formData.append("formFile", this.currentFile);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await this.bookkeepingService.UploadLedgers(formData);
    this.IsLoading = false;
    if (response.status == 200) {
      this.IsProcessed = true;
      setTimeout(() => {
        this.$router.push("/RegisterLedgerTransactions");
      }, 1000);
    }

    this.InError = true;
  }
}
