
import { Component, Prop, Vue } from "vue-property-decorator";
import PosArticle from "../Models/PosArticle";
import { SalesSheet } from "../Models/SalesSheet";
import PosItem from "../components/PosItem.vue";
import PosPayment from "../components/PosPayment.vue";
import SalesService from "../services/SalesService";
import { v4 as uuidv4 } from "uuid";

@Component({
  components: {
    PosItem,
    PosPayment,
  },
})
export default class CashRegister extends Vue {
  private salesSheets = new Array<SalesSheet>();
  private selectedSheet: SalesSheet = new SalesSheet();
  private SalesService: SalesService | null = null;
  private CurrentArticleTab = 0;
  private newSlipName = "";
  private sheetDialog = false;
  private sheetResetHandler = 0;
  private sheetSaveHandler = 0;
  private isPaying = false;

  private customPosArticles = new Array<PosArticle>();

  async PaymentComplete(salesSheet: SalesSheet): Promise<void> {
    console.log(salesSheet.Id);
    await this.SalesService.SalesSheetPayed(salesSheet.Id, salesSheet.PaymentMethod);

    if (salesSheet.StaticSheet == true) {
      salesSheet.Id = uuidv4();
      salesSheet.SoldPosGroupItems.forEach((article) => {
        article.Id = uuidv4();
        article.Quantity = 0;
      });
    } else {
      this.selectedSheet = this.salesSheets[0];
      var idx = this.salesSheets.findIndex((e) => e.Id == salesSheet.Id);
      this.salesSheets.splice(idx, 1);
    }
  }

  private newid(): string {
    return uuidv4();
  }

  private PaymentEvent(isPaying: boolean): void {
    this.isPaying = isPaying;
    clearTimeout(this.sheetResetHandler);
  }

  private QuantityChanged(): void {
    this.SaveSalesSheet();
  }

  private ChangeTab(tab: string): void {
    switch (tab) {
      case "drinks":
        this.CurrentArticleTab = 0;
        break;
      case "food":
        this.CurrentArticleTab = 1;
        break;
      case "custom":
        this.CurrentArticleTab = 2;
        break;
      default:
        this.CurrentArticleTab = 0;
    }
  }

  async SaveSalesSheet(): Promise<void> {
    clearTimeout(this.sheetSaveHandler);
    this.sheetSaveHandler = setTimeout(() => {
      this.SalesService.SaveSheet(this.selectedSheet);
    }, 1000);
    return;
  }

  private SetCurrentSheet(sheet: SalesSheet) {
    this.selectedSheet = sheet;
  }

  async CreateNewSlip(): Promise<void> {
    var sheet = new SalesSheet({ Name: this.newSlipName });
    this.SalesService.FillMissingSalesArticles(sheet);
    this.salesSheets.push(sheet);
    this.selectedSheet = sheet;
    this.sheetDialog = false;
    this.newSlipName = "";
  }

  private AddSalesSheet() {
    this.sheetDialog = true;
  }

  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }
  get IsInRole(): boolean {
    return this.$msal.IsInRole(this.$store.getters.user, "POS.CRU");
  }

  async mounted(): Promise<void> {
    if (!this.IsAuthenticated) {
      try {
        var response = await this.$msal.SilentLogon();
        this.$store.dispatch("UserLoggedIn", response);
        if (!this.IsInRole) this.$router.push("/Overzicht");
      } catch (error) {
        this.$router.push("/home?origin=/pos");
      }
    }

    this.SalesService = new SalesService();

    this.salesSheets = await this.SalesService.getSalesSheets();
    this.selectedSheet = this.salesSheets[0];
  }
}
