
import vue from "vue";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import Person from "../Models/Person";
import Role from "../Models/Role";
import MemberService from "../services/MemberService";

@Component({})
export default class Roles extends Vue {
  @Prop() private person!: Person;

  private roles: Role[] = [];
  private allRoles: Role[] = [];
  private selectedRole = "";
  private MemberService: MemberService | null = null;

  async DeleteRole(roleId: string): Promise<void> {
    if (this.MemberService) {
      await this.MemberService.DeleteRoleFromMember(this.person.Id, roleId);
      this.roles = await this.MemberService.GetRoles(this.person.Id);
    }
  }

  async AddRoleToMember(roleId: string): Promise<void> {
    if (this.MemberService) {
      await this.MemberService.AddRoleToMember(this.person.Id, roleId);
      this.roles = await this.MemberService.GetRoles(this.person.Id);
      this.selectedRole = "";
    }
  }

  async mounted(): Promise<void> {
    this.MemberService = new MemberService();
    this.allRoles = await this.MemberService.GetAllRoles();
    this.roles = await this.MemberService.GetRoles(this.person.Id);
  }
}
