import { BuyersCondition } from "./BuyersCondition";

export class LotSalesMessage {
  constructor(init?: Partial<LotSalesMessage>) {
    Object.assign(this, init);
  }

  Id!: string;
  LotId!: string;
  MarketValue!: number;
  AdditionalAcquisitionCosts!: number;
  AdditionalAcquisitionCostsDescription!: string;
  Subject!: string;
  Message!: string;
  BuyersConditions!: Array<BuyersCondition>;
  HasBeenOffered = false;
  Images: Array<File> | null = null;
}
