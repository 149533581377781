import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0084ff", // #E53935
        secondary: "a2a5ee", // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      },
    },
  },
});
