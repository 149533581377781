
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { InspectionFinding } from "../Models/InspectionFinding";
import LotService from "../services/lotService";

@Component
export default class InspectionFindingItem extends Vue {
  private lotService = new LotService();
  private IsLoading = false;

  @Prop() private inspectionFinding!: InspectionFinding;

  private IsDirty = false;

  MarkDirty(): void {
    this.IsDirty = true;
  }

  get t(): InspectionFinding {
    return this.inspectionFinding;
  }
  async mounted(): Promise<void> {
    return;
  }

  async SaveInspectionFinding(): Promise<void> {
    this.IsLoading = true;
    await this.lotService.SaveInspectionFinding(this.inspectionFinding);
    this.IsLoading = false;
    this.IsDirty = false;
  }
}
