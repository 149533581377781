export default class DateTimeFormatter {
  FormatDate(date: Date | null): string | null {
    if (date === null) return null;
    try {
      const rv = `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
        date.getMonth() + 1
      }-${date.getFullYear()}`;

      return rv;
    } catch (error) {
      return null;
    }
  }

  ParseDate(value: string): Date | null {
    const dateParts = value.split("-") as Array<any>;
    if (isNaN(dateParts[0]) || isNaN(dateParts[1]) || isNaN(dateParts[2])) return null;
    if (dateParts[2].length !== 4) return null;

    // month is 0-based, that's why we need dataParts[1] - 1
    const dateObject = new Date();
    dateObject.setFullYear(+dateParts[2]);
    dateObject.setUTCMonth(dateParts[1] - 1);
    dateObject.setUTCDate(+dateParts[0]);
    dateObject.setUTCHours(0);
    dateObject.setUTCMinutes(0);
    dateObject.setUTCSeconds(0);
    console.log(dateObject);
    return dateObject;
  }
}
