
import { Component, Watch, Vue } from "vue-property-decorator";
import MembersDataTable from "../components/MembersDataTable.vue";
import { InspectionAnswer } from "../Models/InspectionAnswer";
import LotService from "../services/lotService";
import { v4 as uuidv4 } from "uuid";
import { InspectionFinding } from "../Models/InspectionFinding";
import MemberService from "../services/MemberService";

@Component({
  components: {
    MembersDataTable,
  },
})
export default class Inspection extends Vue {
  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  private LotNumber = "";
  private lotService: LotService | null = null;
  private InspectorKnown = false;
  private InspectorId = "";
  private InspectorName = "";
  private memberService: MemberService | null = null;
  private finding: InspectionFinding | null = null;
  private answers: InspectionAnswer[] | null = null;
  private remarks = "";

  private ClearLot(): void {
    this.answers = null;
  }

  private async GetInspector(): Promise<void> {
    if (this.memberService === null) this.memberService = new MemberService();
    var resp = await this.memberService.GetInspector(this.InspectorId);
    if (resp !== null) {
      this.InspectorKnown = true;
      this.InspectorName = resp.Name;
      this.$cookies.set("InspectorId", this.InspectorId);
    }
  }

  private TimeOutHandle = 0;
  private SkipAllotment = false;

  private async ChangeAnswer(item: InspectionAnswer, answer: boolean): Promise<void> {
    item.Answer = answer;

    if (this.TimeOutHandle > 0) {
      clearTimeout(this.TimeOutHandle);
    }

    this.TimeOutHandle = setTimeout(async (): Promise<void> => {
      if (this.lotService === null) this.lotService = new LotService();
      await this.lotService.SubmitAnswer(item);
    }, 2500);
  }

  @Watch("finding.Description")
  private async SubmitRemarks(newvalue: string): Promise<void> {
    if (this.lotService === null) this.lotService = new LotService();
    await this.lotService.SubmitFindingRemark(this.finding);
  }

  private async GetLotQuestions(): Promise<void> {
    if (this.lotService === null) this.lotService = new LotService();
    var resp = await this.lotService.GetLotQuestions(this.LotNumber);
    if (resp.ShouldBeSkipped || resp.OwnedLessThenAYear || resp.ForSale) {
      this.SkipAllotment = true;
      return;
    }
    this.SkipAllotment = false;
    this.finding = resp;
    const GrijsQuestions = resp.Answers.filter((i) => {
      return i.InspectionQuestion?.QuestionType === "Grijs";
    }).sort(function (a, b) {
      return (a.InspectionQuestion?.OrderNr ?? 0) - (b.InspectionQuestion?.OrderNr ?? 0);
    });
    const GroenQuestions = resp.Answers.filter((i) => {
      return i.InspectionQuestion?.QuestionType === "Groen";
    }).sort(function (a, b) {
      return (a.InspectionQuestion?.OrderNr ?? 0) - (b.InspectionQuestion?.OrderNr ?? 0);
    });

    this.answers = GrijsQuestions.concat(GroenQuestions);
  }

  async mounted(): Promise<void> {
    this.lotService = new LotService();
    this.memberService = new MemberService();

    this.InspectorId = this.$cookies.get("InspectorId") ?? null;
    if (this.InspectorId !== null) {
      await this.GetInspector();
    }
  }
}
