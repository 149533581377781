
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

import MemberService from "../services/MemberService";
import BankAccount from "../Models/BankAccount";

@Component
export default class BankAccountCard extends Vue {
  @Prop() private PersonId!: string;

  private memberService = new MemberService();

  private bankAccounts = new Array<BankAccount>();

  formatDate(date: Date | null): string | null {
    if (date === null) return null;
    return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }

  async mounted(): Promise<void> {
    var rv = await this.memberService.GetBankAccounts(this.PersonId);
    if (rv !== null) this.bankAccounts = rv;
  }
}
