export default class WaterMeasurement {
  Id!: string;
  LotNumber!: number | null;
  ReferenceDate!: Date | null;
  WaterMeasurementm3Prev!: number | null;
  WaterMeasurementm3!: number | null;
  NeedsToBeReplaced = true;
  Hash: string | null = null;
  ReasonForNeedingHelp: string | null = null;
  NeedHelp = false;
}
