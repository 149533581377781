import { ConditionMeasurement } from "./ConditionMeasurement";
import { WaterMeasurement } from "./WaterMeasurement.1";

export class Lot {
  constructor(init?: Partial<Lot>) {
    Object.assign(this, init);
  }

  ConditionMeasurements!: ConditionMeasurement[];
  ForSale!: boolean;
  ForSaleSince!: Date | null;
  Id!: string;
  LastEvaluationTime!: Date | null;

  LastEvaluationValue!: number;
  EvaluationDoneBy!: string;
  EvaluationPlannedAt!: Date | null;
  LotId!: string;
  AmountGoodsForTakeOver = 0;
  WaterMeasurements!: WaterMeasurement[];
  BeingSoldBy = "";
}
