
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

import MemberService from "../services/MemberService";

@Component
export default class KassaladeOmzet extends Vue {
  @Prop() private WeekNummer!: number;

  private memberService = new MemberService();

  formatDate(date: Date | null): string | null {
    if (date === null) return null;
    return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }

  async mounted(): Promise<void> {
    return;
  }
}
