
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Lot } from "../Models/Lot";
import { LotSale } from "../Models/LotSale";
import { OfferedTo } from "../Models/OfferedTo";
import LotService from "../services/lotService";

@Component
export default class LotsForSale extends Vue {
  private offeredHouses = new Array<LotSale>();
  private offeredTo = new Array<OfferedTo>();
  private selectedOffering: OfferedTo | null = null;
  private selectedLot: LotSale | null = null;
  private LastLookAtLots: Date | null = null;
  private AnyOneAcceptedOffer = false;
  private lotService!: LotService;

  private offeredHousesOptions = {
    itemsPerPage: 10,
    page: 1,
  };

  private offeredToHeaders = [
    { text: "Plaats in wachtrij", align: "start", value: "WaitListRanking" },
    {
      text: "Naam aspirant lid",
      align: "start",
      value: "Name",
    },
    {
      text: "Telnr",
      align: "start",
      value: "PrimaryPhoneNumber",
    },
    {
      text: "email",
      align: "start",
      value: "MailAddress",
    },
    { text: "Geintresseerd", align: "start", value: "Interested" },
    { text: "Bezichting gepland op", align: "start", value: "ViewingPlannedAt" },
    { text: "Actie", align: "start", value: "actions" },
  ];

  private headers = [
    {
      text: "Tuin nr",
      align: "start",
      value: "LotId",
    },
    {
      text: "Naam lid",
      align: "start",
      value: "Name",
    },
    {
      text: "Te koop sinds",
      align: "start",
      value: "ForSaleSince",
    },
    {
      text: "Taxatie gepland op",
      align: "start",
      value: "EvaluationPlannedAt",
    },
    {
      text: "Gedaan door",
      align: "start",
      value: "EvaluationDoneBy",
    },
    {
      text: "Verkoop waarde",
      align: "start",
      value: "MarketValue",
    },
    {
      text: "Verkocht door",
      align: "start",
      value: "BeingSoldBy",
    },
    {
      text: "Aangeboden",
      align: "start",
      value: "HasBeenOffered",
    },
    {
      text: "",
      align: "start",
      value: "Action",
    },
  ];

  private SetSelected(item: LotSale): void {
    this.selectedLot = item;
    this.$emit("SelectedLotChanged", item);
    this.lotService.GetOfferedTo(item.Id).then((result: Array<OfferedTo>) => {
      const orderedItems = result.sort(function (a, b) {
        return a.WaitListRanking - b.WaitListRanking;
      });
      this.offeredTo = orderedItems;
      this.AnyOneAcceptedOffer = orderedItems.filter((x) => x.AcceptedOffer === true).length > 0;
    });
  }

  @Emit()
  GotoOfferTab(): void {
    this.$emit("goto-offer-tab");
  }

  private SetResultTo(item: OfferedTo, acceptedOffering: boolean): void {
    if (acceptedOffering) {
      item.AcceptedOffer = true;
      item.RejectedOffer = false;
      this.AnyOneAcceptedOffer = true;
    } else {
      item.AcceptedOffer = false;
      item.RejectedOffer = true;
    }

    this.lotService.UpdateOffering(item);
  }

  private SetSelectedOffering(item: OfferedTo): void {
    this.selectedOffering = item;
  }

  @Watch("selectedOffering", { deep: true })
  private async offeredToChanged(newValue: OfferedTo, oldValue: OfferedTo): Promise<void> {
    if (oldValue == null) {
      return;
    }
    await this.lotService.UpdateOffering(newValue);
  }

  parseDate(value: string): Date | null {
    var dateParts = value.split("-") as Array<any>;
    if (isNaN(dateParts[0]) || isNaN(dateParts[1]) || isNaN(dateParts[2])) return null;
    if (dateParts[2].length !== 4) return null;

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date();
    dateObject.setFullYear(+dateParts[2]);
    dateObject.setUTCMonth(dateParts[1] - 1);
    dateObject.setUTCDate(+dateParts[0]);
    dateObject.setUTCHours(0);
    dateObject.setUTCMinutes(0);
    dateObject.setUTCSeconds(0);
    return dateObject;
  }

  formatDate(date: Date | string | null): string {
    try {
      if (date === null) return "";
      if (!(date instanceof Date)) return date;

      return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
    } catch (error) {
      return "";
    }
  }

  formatAmount(value: number): string {
    const formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  async mounted(): Promise<void> {
    var currentLastLookAtLots = this.$cookies.get("LastLookAtLots");
    if (currentLastLookAtLots) {
      this.LastLookAtLots = new Date(currentLastLookAtLots);
    }
    var today = new Date().toDateString();
    this.$cookies.set("LastLookAtLots", today);

    this.lotService = new LotService();
    const lots = await this.lotService.LotsForSale();
    this.offeredHouses.forEach((lot) => {
      lot.NewReactionReceived = (lot.LastResponseRecievedAt ?? new Date(1, 1, 1)) > (this.LastLookAtLots ?? new Date());
    });
    this.offeredHouses = lots;
  }
}
