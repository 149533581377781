export class BuyersCondition {
  constructor(init?: Partial<BuyersCondition>) {
    Object.assign(this, init);
  }

  Id!: string;
  LotSalesMessageId!: string;

  Condition!: string;
  ConditionDeposit!: number;

  Images!: Array<File>;

  IsNew = false;
}
