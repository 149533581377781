
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LotService from "../services/lotService";
import { SalesReaction } from "../Models/SalesReaction";
@Component
export default class RegisterHouseOffering extends Vue {
  @Prop() private msg!: string;

  private lotService = new LotService();
  private reaction: SalesReaction = {
    Response: "",
    IntressedForALotInTheRange: 0,
    WillBuild: "Ja",
    HasLooked: "Nee",
  };

  private rangeRules = [
    (v: number) => !!v || "Bedrag is verplicht",
    (v: number) => v >= 1200 || "minimaal 1.200 euro",
    (v: number) => v <= 15000 || "maximaal 15.000 euro",
  ];

  private NoRange(i: number) {
    this.reaction.IntressedForALotInTheRange = i;
  }

  private timeout = 0;

  @Watch("reaction", { deep: true })
  private reactionChanged() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.lotService.RegisterResponse(this.reaction);
    }, 1000);
  }

  async mounted(): Promise<void> {
    var response = this.$route.query["response"] as string | null;
    if (response == null) {
      return Promise.resolve();
    }
    this.reaction.Response = response;
    var preferences = await this.lotService.GetSalesPreferences(response);
    this.reaction.IntressedForALotInTheRange = preferences.IntressedForALotInTheRange;
    this.reaction.WillBuild = preferences.WillBuild;

    this.reactionChanged();
  }
}
