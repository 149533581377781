
import { Component, Watch, Vue } from "vue-property-decorator";
import MembersDataTable from "../components/MembersDataTable.vue";

@Component({
  components: {
    MembersDataTable,
  },
})
export default class Lot extends Vue {
  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  async mounted(): Promise<void> {
    if (!this.IsAuthenticated) {
      try {
        var response = await this.$msal.SilentLogon();
        this.$store.dispatch("UserLoggedIn", response);
      } catch (error) {
        this.$router.push("/home");
      }
    }
    const user = this.$store.getters.user;

    if (!this.$msal.IsInRole(user, "Member.CRUD")) {
      if (this.$msal.IsInRole(user, "CashRegister.CRU")) this.$router.push("/VerkoopRegistratie");
      if (this.$msal.IsInRole(user, "POS.CRU")) this.$router.push("POS");
    }
  }
}
