import { AxiosResponse } from "axios";
import { InspectionFinding } from "../Models/InspectionFinding";
import { Lot } from "../Models/Lot";
import { OfferedTo } from "../Models/OfferedTo";
import { LotSalesMessage } from "../Models/LotSalesMessage";
import { LotSale } from "../Models/LotSale";
import { SalesReaction } from "../Models/SalesReaction";

import HttpClient from "./HttpClient";
import { InspectionAnswer } from "../Models/InspectionAnswer";
import { SalesPreference } from "../Models/SalesPreference";

export default class LotService {
  httpClient = new HttpClient("Lot");

  async GetLotIds(LotNumber: string): Promise<{ MemberId: string; LotId: string }> {
    const response = await this.httpClient.Get<{ MemberId: string; LotId: string }>(`GetIdsByLotNumber/${LotNumber}`);
    return response;
  }

  async GetLot(LotId: string): Promise<Lot> {
    const response = await this.httpClient.Get<Lot>(`${LotId}`);
    return response;
  }

  async ResetLotAvailablitiy(LotId: string): Promise<void> {
    await this.httpClient.Put<string, null>(LotId + "/MarkNotForSale", null);
  }

  async AssignNewMember(SelectedMemberForOwnerShip: string | null, LotId: string): Promise<void> {
    await this.httpClient.Post(`${LotId}/AssignMember`, SelectedMemberForOwnerShip);
  }

  async IsForSale(LotId: string): Promise<boolean> {
    if (!LotId) return false;
    const lot = await this.GetLot(LotId);
    return lot.ForSale;
  }

  async MarkLotForSale(LotId: string): Promise<void> {
    await this.httpClient.Put<string, null>(LotId + "/MarkForSale", null);
  }

  async UpdateLot(lot: Lot): Promise<AxiosResponse<void>> {
    return await this.httpClient.Put<Lot, void>(lot.Id, lot);
  }

  formatDateTime(date: Date | null): string | null {
    if (date === null) return null;

    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }

  async GetLotBuyer(Id: string): Promise<OfferedTo> {
    return await this.httpClient.Get<OfferedTo>(`${Id}/GetLotBuyer`);
  }

  async GetInspectionFindings(LotId: string): Promise<InspectionFinding[]> {
    const response = await this.httpClient.Get<InspectionFinding[]>(`${LotId}/InspectionFindings`);
    response.forEach((element) => {
      element.ReferenceDate = this.formatDateTime(new Date(Date.parse(element.ReferenceDate as string)));
      element.DueDate = this.formatDateTime(new Date(Date.parse(element.DueDate as string)));
    });
    return response;
  }

  async SaveInspectionFinding(inspectionFinding: InspectionFinding): Promise<void> {
    await this.httpClient.Put(`${inspectionFinding.LotId}/InspectionFindings`, inspectionFinding);
  }

  async LotsForSale(): Promise<LotSale[]> {
    const response = await this.httpClient.Get<LotSale[]>(`ForSale`);
    return response;
  }

  async GetLotSalesMessage(Id: string): Promise<LotSalesMessage> {
    const response = await this.httpClient.Get<LotSalesMessage>(`${Id}/LotSalesMessage`);
    return response;
  }

  async UpdateLotSalesMessage(lotSalesMessage: LotSalesMessage): Promise<void> {
    await this.httpClient.Put(`${lotSalesMessage.LotId}/LotSalesMessage`, lotSalesMessage);
  }

  async RegisterResponse(salesReaction: SalesReaction): Promise<void> {
    await this.httpClient.Post(`RegisterResponse`, salesReaction);
  }

  async GetOfferedTo(lotId: string): Promise<OfferedTo[]> {
    return await this.httpClient.Get(`${lotId}/GethouseOfferings`);
  }

  async UpdateOffering(offer: OfferedTo): Promise<void> {
    await this.httpClient.Put(`${offer.Id}/UpdateOffering`, offer);
  }

  async SendOffering(LotId: string): Promise<void> {
    await this.httpClient.Post(`${LotId}/SendOffering`, null);
  }

  async GetLotQuestions(LotNumber: string): Promise<InspectionFinding> {
    return await this.httpClient.Get(`${LotNumber}/InspectionQuestions`);
  }

  async SubmitAnswer(answer: InspectionAnswer): Promise<void> {
    await this.httpClient.Put(`${answer.LotId}/SubmitAnswer`, answer);
  }

  async GetWaitListMemberCount(lotId: string, min: number, max: number): Promise<number> {
    const response = await this.httpClient.Get<number>(`${lotId}/GetWaitListMemberCount/${min}/${max}`);
    return response;
  }

  async SubmitFindingRemark(finding: InspectionFinding | null): Promise<void> {
    await this.httpClient.Put(`${finding?.LotId}/SubmitFindingRemark`, finding);
  }

  async GetSalesPreferences(response: string): Promise<SalesPreference> {
    const salesPreference = await this.httpClient.Post<string, SalesPreference>(`GetSalesPreferences`, response);
    return salesPreference.data;
  }
}
