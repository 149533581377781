
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { WeekOmzetRegel } from "../Models/Plu";
import SalesService from "../services/SalesService";
@Component
export default class VerkochtArtikel extends Vue {
  @Prop() private VerkoopRegel!: WeekOmzetRegel;
  @Prop() private SearchCrit!: string;
  @Prop() private IsReadOnly!: boolean;

  private salesService = new SalesService();

  private IsDirty = false;
  private IsUpdating = false;
  private TimeOutHandle = 0;

  formatAmount(value: number): string {
    const formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  get MatchesCrit(): boolean {
    if (this.IsReadOnly && this.VerkoopRegel.Aantal === 0) return false;
    if (!this.SearchCrit || this.SearchCrit === "") return true;
    return this.VerkoopRegel.PluNaam.toLowerCase().indexOf(this.SearchCrit.toLowerCase()) > -1;
  }

  get TotaalRegel(): string {
    this.$emit(
      "RowAmountChanged",
      this.VerkoopRegel.PluNummer,
      this.VerkoopRegel.Prijs * this.VerkoopRegel.Aantal,
      this.VerkoopRegel.Aantal
    );
    return this.formatAmount(this.VerkoopRegel.Prijs * this.VerkoopRegel.Aantal);
  }

  @Watch("VerkoopRegel", { immediate: false, deep: true })
  PropertyChange(newValue: WeekOmzetRegel, oldValue: WeekOmzetRegel): void {
    if (!oldValue || oldValue.Id === "") return;

    this.IsDirty = true;
    if (this.TimeOutHandle > 0) {
      clearTimeout(this.TimeOutHandle);
    }

    this.TimeOutHandle = setTimeout(async (): Promise<void> => {
      await this.CommitChanges(newValue);
    }, 1000);
  }

  async CommitChanges(regel: WeekOmzetRegel): Promise<void> {
    this.IsUpdating = true;
    let a: WeekOmzetRegel[] = [];
    a.push(regel);
    await this.salesService.SaveSoldItems(a);
    this.IsUpdating = false;
    this.IsDirty = false;
  }
}
