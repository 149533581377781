
import vue from "vue";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import LotService from "../services/lotService";
import { Lot } from "../Models/Lot";

@Component
export default class LotCard extends Vue {
  @Prop() private LotId!: string;
  @Prop() private ForSale!: boolean;

  private lotService = new LotService();
  private Lot = new Lot();
  private TimeOutHandle = 0;

  formatDate(date: Date | null): string | null {
    if (date === null) return null;
    try {
      var rv = `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
        date.getMonth() + 1
      }-${date.getFullYear()}`;

      return rv;
    } catch (error) {
      return null;
    }
  }

  parseDate(value: string): Date | null {
    var dateParts = value.split("-") as Array<any>;
    if (isNaN(dateParts[0]) || isNaN(dateParts[1]) || isNaN(dateParts[2])) return null;
    if (dateParts[2].length !== 4) return null;

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date();
    dateObject.setFullYear(+dateParts[2]);
    dateObject.setUTCMonth(dateParts[1] - 1);
    dateObject.setUTCDate(+dateParts[0]);
    dateObject.setUTCHours(0);
    dateObject.setUTCMinutes(0);
    dateObject.setUTCSeconds(0);
    console.log(dateObject);
    return dateObject;
  }

  @Watch("Lot", { deep: true })
  async onPropertyChanged(oldValue: Lot, newValue: Lot): Promise<void> {
    if (!oldValue || !oldValue.Id || oldValue.Id === "") return;
    if (!newValue || !newValue.Id || newValue.Id === "") return;
    var ls = Object.assign({}, newValue);

    if (this.TimeOutHandle > 0) {
      clearTimeout(this.TimeOutHandle);
    }

    this.TimeOutHandle = setTimeout(async (): Promise<void> => {
      console.log(ls.LastEvaluationTime);
      if (ls.LastEvaluationTime === null || ls.LastEvaluationTime < new Date(1990, 1, 1)) {
        let dateObject = new Date(2000, 0, 1);
        dateObject.setFullYear(2000);
        dateObject.setUTCMonth(0);
        dateObject.setUTCDate(1);
        dateObject.setUTCHours(0);
        dateObject.setUTCMinutes(0);
        dateObject.setUTCSeconds(0);
        ls.LastEvaluationTime = dateObject;
        this.Lot.LastEvaluationTime = dateObject;
      }
      await this.lotService.UpdateLot(ls);
    }, 1000);
  }

  async mounted(): Promise<void> {
    if (!this.LotId) return;
    this.Lot = await this.lotService.GetLot(this.LotId);
    this.ForSale = this.Lot.ForSale;
  }
}
