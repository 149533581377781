
import vue from "vue";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import PersonModel from "../Models/Person";
import MemberService from "../services/MemberService";

import DateTimePickerField from "./DateTimePickerField.vue";
import Roles from "./Roles.vue";

@Component({
  components: { DateTimePickerField, Roles },
})
export default class Person extends Vue {
  @Prop() private PersonId!: string;

  CurrentPerson = new PersonModel();
  private memberService = new MemberService();
  private TimeOutHandle = 0;
  private IsDirty = false;
  private IsUpdating = false;

  private PersonStart = "";

  CreatePartner(): void {
    this.$emit("register-partner", this.PersonId);
  }

  parseDate(value: string): Date | null {
    var dateParts = value.split("-") as Array<any>;
    if (isNaN(dateParts[0]) || isNaN(dateParts[1]) || isNaN(dateParts[2])) return null;
    if (dateParts[2].length !== 4) return null;

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date();
    dateObject.setFullYear(+dateParts[2]);
    dateObject.setUTCMonth(dateParts[1] - 1);
    dateObject.setUTCDate(+dateParts[0]);
    dateObject.setUTCHours(0);
    dateObject.setUTCMinutes(0);
    dateObject.setUTCSeconds(0);
    return dateObject;
  }

  BackToMemberList(): void {
    this.$router.push({ name: "Kavels/tuinen", params: { LotMembers: "false" } });
  }

  formatDate(date: Date | null): string | null {
    if (date === null) return null;
    return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }

  async mounted(): Promise<void> {
    if (!this.PersonId) return;
    this.CurrentPerson = await this.memberService.GetMember(this.PersonId);
  }

  @Watch("PersonId")
  async pchange(): Promise<void> {
    this.CurrentPerson = await this.memberService.GetMember(this.PersonId);
  }

  @Watch("CurrentPerson", { immediate: false, deep: true })
  PropertyChange(newValue: PersonModel, oldValue: PersonModel): void {
    if (!oldValue || oldValue.Id === "") return;
    if (newValue.Start === null) return;
    this.IsDirty = true;
    if (this.TimeOutHandle > 0) {
      clearTimeout(this.TimeOutHandle);
    }

    this.TimeOutHandle = setTimeout(async (): Promise<void> => {
      await this.CommitChanges(newValue);
    }, 1000);
  }

  async EndMemberShip(): Promise<void> {
    this.CurrentPerson.End = new Date();
    await this.CommitChanges(this.CurrentPerson);
    this.$router.push({ name: "Kavels/tuinen", params: { LotMembers: "false" } });
  }

  async CommitChanges(person: PersonModel): Promise<void> {
    this.IsUpdating = true;
    await this.memberService.UpdateMember(person);
    this.IsUpdating = false;
    this.IsDirty = false;
  }

  SendMailTo(mailAddress: string): void {
    window.location.href = "mailto:" + mailAddress;
  }

  CallMember(Number: string): void {
    window.location.href = "tel:" + Number;
  }
}
