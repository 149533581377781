
import { Component, Vue } from "vue-property-decorator";
import WaterMeasurement from "../Models/WaterMeasurement";
import MemberService from "../services/MemberService";
import date from "date-and-time";

@Component
export default class extends Vue {
  LotNumber = "";
  hash: string | null = null;
  WaterMeasurement = 0;
  RegistrationDate = date.format(new Date(), "DD-MM-YYYY");
  ReasonForNeedingHelp = "";
  NeedHelp = false;
  IsSaved = false;

  private InformationSaved: boolean | null = null;
  private currentRcd: WaterMeasurement = new WaterMeasurement();
  private memberService = new MemberService();

  mounted(): void {
    const lotnr: string | null = this.$route.query["LotNr"] as string | null;
    const hash: string | null = this.$route.query["hash"] as string | null;

    this.LotNumber = lotnr ?? "";
    this.hash = hash;
  }

  async SendPreference(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.IsSaved = true;
    this.currentRcd.ReferenceDate = date.parse(this.RegistrationDate.trim(), "DD-MM-YYYY", true);
    this.currentRcd.LotNumber = parseInt(this.LotNumber);
    this.currentRcd.NeedHelp = this.NeedHelp;
    this.currentRcd.Hash = this.hash;
    this.currentRcd.ReasonForNeedingHelp = this.ReasonForNeedingHelp;
    this.currentRcd.WaterMeasurementm3 = this.WaterMeasurement;

    const response = await this.memberService.PostWaterMeasurement(this.currentRcd);
    if (response.status === 200) {
      this.InformationSaved = true;
    } else {
      this.InformationSaved = false;
    }
  }
}
