import { render, staticRenderFns } from "./WaterMeasurementsCard.vue?vue&type=template&id=8f37b500&"
import script from "./WaterMeasurementsCard.vue?vue&type=script&lang=ts&"
export * from "./WaterMeasurementsCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports