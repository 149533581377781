
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

import MemberService from "../services/MemberService";
import WaterMeasurement from "../Models/WaterMeasurement";

@Component
export default class WaterMeasurementsCard extends Vue {
  @Prop() private PersonId!: string;

  private memberService = new MemberService();

  private waterMeasurements = new Array<WaterMeasurement>();

  async mounted(): Promise<void> {
    var rv = await this.memberService.GetWaterMeasurements(this.PersonId);
    if (rv !== null) this.waterMeasurements = rv;
  }
}
