import _Vue from "vue";

export class AppSettings {
  public ApiAddress = process.env.VUE_APP_APIADDRESS;
  public RootUrl = process.env.VUE_APP_ROOTURL;
  public Clientid = process.env.VUE_APP_CLIENTID;
  public TenantId = process.env.VUE_APP_TENANTID;
}

export function ConstantFactory(Vue: typeof _Vue, constants: AppSettings): void {
  Vue.prototype.$Constants = constants;
}
