
import Vue from "vue";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import { LotSale } from "../Models/LotSale";
import { OfferedTo } from "../Models/OfferedTo";
import LotService from "../services/lotService";
import dtFormatter from "../lib/DateTimeFormatter";

@Component({
  components: {
    SalesInvoiceCard: () => import("./SalesInvoiceCard.vue"),
  },
})
export default class ChangeOwnership extends Vue {
  @Prop() private selectedLot!: LotSale;

  private offeringDetails: null | OfferedTo = null;
  private dtFormatter = new dtFormatter();

  @Watch("selectedLot", { deep: true })
  async onPropertyChanged(oldValue: LotSale, newValue: LotSale): Promise<void> {
    var lotService = new LotService();
    var buyer = await lotService.GetLotBuyer(newValue.Id);
    if (buyer != null) {
      this.offeringDetails = buyer;
    }
  }

  async mounted(): Promise<void> {
    if (this.selectedLot.Id != null) {
      var lotService = new LotService();
      var buyer = await lotService.GetLotBuyer(this.selectedLot.Id);
      console.log(buyer);
      if (buyer != null) {
        this.offeringDetails = buyer;
        console.log(this.offeringDetails);
      }
    }
  }
}
