
import { Component, Vue } from "vue-property-decorator";
import User from "../Models/User";
import BookkeepingService from "../services/Bookkeepingservice";
import CurrentLedgerState from "./CurrentLedgerState.vue";

@Component({
  components: {
    CurrentLedgerState,
  },
})
export default class BookingDashboard extends Vue {
  bookKeepingService = new BookkeepingService();
  BookingYears = [new Date().getFullYear()];
  SelectedYear = new Date().getFullYear();

  async mounted(): Promise<void> {
    this.lastImportDate = await this.bookKeepingService.LastImportDate();
    this.BookingYears = await this.bookKeepingService.AvailableBookingYears();
  }

  RegisterLedgers(): void {
    this.$router.push("RegisterLedgerTransactions");
  }

  ImportBankStatements(): void {
    this.$router.push("ImportBankStatements");
  }

  IsInRole(role: string): boolean {
    const user: User = this.$store.getters.user;
    return this.$msal.IsInRole(user, role);
  }

  lastImportDate: Date | null = null;

  formatDateTime(date: Date | null): string | null {
    if (date === null) return null;

    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;
  }
}
