
import vue from "vue";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

import MemberService from "../services/MemberService";
import PosArticle from "../Models/PosArticle";
import { SalesSheet } from "../Models/SalesSheet";

@Component({})
export default class PosPayment extends Vue {
  @Prop() private salesSheet!: SalesSheet;

  @Emit()
  PaymentCompleted(): SalesSheet {
    this.salesSheet.GiftCardValue = this.GiftCardValue;
    this.IsPaying = false;
    return this.salesSheet;
  }

  @Emit()
  PaymentEvent(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return this.IsPaying;
  }

  private memberService = new MemberService();
  private IsPaying = false;
  private GiftCardValue = 0;

  get PaymentMethodIsSelected(): boolean {
    return this.PaymentMethod == "" && this.WhoIsPaying == "";
  }
  private WhoIsPaying = "Funded";
  private PaymentMethod = "";

  formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  private PaymentStart() {
    this.IsPaying = true;
    this.$emit("payment-event", this.IsPaying);
  }

  private ChangeWhoIsPaying(who: string) {
    this.WhoIsPaying = who;
  }

  private PaymentComplete() {
    this.salesSheet.PaymentMethod = this.PaymentMethod;
    this.salesSheet.WhoIsPaying = this.WhoIsPaying;
    this.IsPaying = false;
    this.PaymentMethod = "";
    this.$emit("payment-completed", this.salesSheet);
    this.$emit("payment-event", this.IsPaying);
  }

  private PaymentCanceled() {
    this.PaymentMethod = "";
    this.IsPaying = false;
    this.$emit("payment-event", this.IsPaying);
  }

  get OnCredit(): boolean {
    return this.salesSheet.CanBuyOnCredit;
  }

  get totalPrice(): number {
    var sold = this.salesSheet.SoldPosGroupItems.filter((posItem) => posItem.Quantity > 0);
    return sold.reduce((part, c) => part + c.Price * c.Quantity, 0);
  }

  get soldItems(): Array<PosArticle> {
    return this.salesSheet.SoldPosGroupItems.filter((posItem) => posItem.Quantity > 0);
  }
}
