import { AxiosResponse } from "axios";
import BankAccount from "../Models/BankAccount";
import FileInformation from "../Models/FileInformation";
import InvoicePreference from "../Models/InvoicePreference";
import { MemberOverview } from "../Models/MemberOverview";
import Person from "../Models/Person";
import WaterMeasurement from "../Models/WaterMeasurement";
import HttpClient from "./HttpClient";
import { InvoiceOverview } from "../Models/InvoiceOverview";
import Inspector from "../Models/Inspector";
import Role from "../Models/Role";
import { SalesInvoice } from "../Models/SalesInvoice";

export default class MemberService {
  httpClient = new HttpClient("Member");

  async CreateNewMember(): Promise<string> {
    const response = await this.httpClient.Post<null, string>("", null);
    return response.data as string;
  }

  async CreatePartnerMember(personId: string): Promise<string> {
    const response = await this.httpClient.Post<unknown, string>(`${personId}/NewPartner`, null);
    return response.data;
  }

  async GetMember(MemberId: string): Promise<Person> {
    const response = await this.httpClient.Get<Person>(`${MemberId}`);
    return response;
  }

  async GetSalesInvoice(houseOfferingId: string): Promise<SalesInvoice> {
    const response = await this.httpClient.Get<SalesInvoice>(`SalesInvoice/${houseOfferingId}`);
    return response;
  }

  async SendInvoice(Id: string): Promise<void> {
    await this.httpClient.Post(`SalesInvoice/${Id}`, null);
  }

  async GetMembers(
    MembersWithLot: boolean,
    roles: Array<string> = [],
    MinAge = 18,
    MaxAge = 99,
    AllHouses = true,
    BuddyDirector: string | null = null
  ): Promise<Array<MemberOverview>> {
    const response = await this.httpClient.Get<Array<MemberOverview>>(
      "",
      undefined,
      `MembersWithLot=${MembersWithLot}${roles ? "&Roles=" + roles : "&Roles="}${MinAge ? "&MinAge=" + MinAge : ""}${
        MaxAge ? "&MaxAge=" + MaxAge : ""
      }${"&AllHouses=" + AllHouses}` + (BuddyDirector ? "&BuddyDirector=" + BuddyDirector : "")
    );

    const OverView = new Array<MemberOverview>();
    response.forEach((member) => {
      const i = new MemberOverview();
      i.Address = member.Address;
      i.City = member.City;
      i.MailAddress = member.MailAddress;
      i.LotId = member.LotId;
      i.LotNumber = member.LotNumber;
      i.Name = member.Name;
      i.PostalCode = member.PostalCode;
      i.PrimaryPhoneNumber = member.PrimaryPhoneNumber;
      i.DateOfBirth = member.DateOfBirth;
      i.MemberId = member.MemberId;
      i.BuddyDirector = member.BuddyDirector;
      i.MemberSince = member.MemberSince;
      i.IntakeDoneAt = member.IntakeDoneAt;
      OverView.push(i);
    });

    return OverView;
  }

  async GetInvoiceOverview(MemberId: string): Promise<Array<InvoiceOverview>> {
    const response = await this.httpClient.Get<Array<InvoiceOverview>>(`${MemberId}/InvoiceOverview`);
    return response;
  }

  async PostWaterMeasurement(currentRcd: WaterMeasurement): Promise<AxiosResponse> {
    return await this.httpClient.Post("RegisterWaterMeasurement", currentRcd);
  }

  async PostInvoicePrefernce(pref: InvoicePreference): Promise<AxiosResponse> {
    const response = await this.httpClient.Post("InvoicePreference", pref);
    return response;
  }

  async GetPartnerIdOfMember(MemberId: string): Promise<string | null> {
    const response = await this.httpClient.Get<string>(`PartnerIdOfMember/${MemberId}`);
    return response ? response : null;
  }

  async GetBankAccounts(MemberId: string): Promise<Array<BankAccount> | null> {
    const response = await this.httpClient.Get<Array<BankAccount>>(`${MemberId}/BankAccounts/`);
    return response ? response : null;
  }

  async GetWaterMeasurements(MemberId: string): Promise<Array<WaterMeasurement> | null> {
    const response = await this.httpClient.Get<Array<WaterMeasurement>>(`${MemberId}/WaterMeasurement/`);
    return response ? response : null;
  }

  async UpdateMember(person: Person): Promise<void> {
    await this.httpClient.Put("", person);
  }

  async GetRoles(memberId: string): Promise<Role[]> {
    const response = await this.httpClient.Get<Role[]>(`${memberId}/Roles/`);
    return response;
  }

  async GetAllBuddies(): Promise<string[]> {
    const response = await this.httpClient.Get<string[]>(`Buddies`);
    return response;
  }

  async GetAllRoles(): Promise<Role[]> {
    return await this.httpClient.Get<Role[]>("Roles");
  }

  async AddRoleToMember(Id: string, roleId: string): Promise<void> {
    await this.httpClient.Post(`${Id}/Role/${roleId}`, null);
  }

  async GetInspector(InspectorId: string): Promise<Inspector | null> {
    try {
      const response = await this.httpClient.Get<Inspector | null>(`SpectatorAbbrivation/${InspectorId}`);
      return response ? response : null;
    } catch (error) {
      return null;
    }
  }

  async GetIdsForLotNumber(lotNumber: string): Promise<{ Item1: string; Item2: string }> {
    const response = await this.httpClient.Get<{ Item1: string; Item2: string }>(`IdsForLot/${lotNumber}`);

    return response;
  }

  async DeleteRoleFromMember(Id: string, roleId: string): Promise<void> {
    await this.httpClient.Delete(`${Id}/Role/${roleId}`, "", "");
  }
}
