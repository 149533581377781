export default class InvoicePreference {
  Address!: string | null;
  City!: string | null;
  Email!: string | null;
  Id!: string | null;
  Lot!: string | null;
  Name!: string | null;
  SendByMail!: boolean | null;
  ZipCode!: string | null;
  MemberId!: string | null;
  Hash!: string | null;
}
