
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { LedgerState } from "../Models/LedgerState";

import BookkeepingService from "../services/Bookkeepingservice";
import { LedgerResult } from "../Models/LedgerResult";

@Component
export default class CurrentLedgerState extends Vue {
  @Prop() private BookYear!: number;

  get CurrentBookYear(): number {
    return this.BookYear;
  }

  state = new LedgerState();
  bookKeepingService = new BookkeepingService();

  async GetCurrentState(): Promise<void> {
    console.log(`currentbookyear ${this.CurrentBookYear}`);

    let response = await this.bookKeepingService.FetchCurrentState(this.CurrentBookYear);
    if (response !== null) {
      this.state.LedgerCreditResult = response.filter((e) => {
        return e.DebetOrCredit;
      });
      this.state.LedgerDebetResult = response.filter((e) => {
        return !e.DebetOrCredit;
      });
    }

    let totalGained = this.state.LedgerDebetResult.reduce((a, c) => {
      return a + c.Amount;
    }, 0);

    let TotalDeficit = this.state.LedgerCreditResult.reduce((a, c) => {
      return a + c.Amount;
    }, 0);

    this.state.LedgerCreditResult.push(
      new LedgerResult({
        Amount: TotalDeficit,
        LedgerDescription: "Totaal",
      })
    );

    this.state.LedgerDebetResult.push(
      new LedgerResult({
        Amount: totalGained,
        LedgerDescription: "Totaal",
      })
    );
  }

  @Watch("BookYear")
  async OnChange(newValue: number): Promise<void> {
    console.log(this.BookYear);
    await this.GetCurrentState();
  }

  async mounted(): Promise<void> {
    await this.GetCurrentState();
  }

  ShowDetailsOfLedger(ledger: LedgerResult): void {
    console.log(ledger);
    this.$router.push({
      name: "RegisterLedgerTransactions",
      params: { BookingYear: `${this.CurrentBookYear}`, LedgerCode: ledger.LedgerCode },
    });
  }
}
