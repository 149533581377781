
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import WaterMeasurementService from "../services/WaterMeasurementService";
import WaterMeasurement from "../Models/WaterMeasurement";

@Component
export default class WaterMeasurements extends Vue {
  @Prop() private msg!: string;

  items = new Array<WaterMeasurement>();
  item = new WaterMeasurement();
  itemIndex = 0;
  InError = false;
  CurrentMeasurement = 0;

  $refs!: {
    LotNumber: HTMLFormElement;
    WatermeasurementCard: HTMLFormElement;
  };

  private MeasurementService = new WaterMeasurementService();

  async mounted(): Promise<void> {
    var items = await this.MeasurementService.Get();
    items.forEach((element) => {
      var mtr = new WaterMeasurement();
      mtr.LotNumber = element.LotNumber;
      mtr.WaterMeasurementm3Prev = element.WaterMeasurementm3Prev;
      mtr.WaterMeasurementm3 = element.WaterMeasurementm3;
      mtr.NeedsToBeReplaced = element.NeedsToBeReplaced;
      this.items.unshift(mtr);
    });

    var mtr = new WaterMeasurement();
    mtr.LotNumber = null;
    mtr.WaterMeasurementm3Prev = null;
    mtr.WaterMeasurementm3 = null;
    mtr.NeedsToBeReplaced = true;
    this.items.unshift(mtr);

    this.item = this.items[0];
    this.$refs.LotNumber.focus();
  }

  private Headers = [
    {
      text: "Tuin nr",
      align: "start",
      value: "LotNumber",
    },
    { text: "Vorige stand", value: "WaterMeasurementm3Prev", sortable: false },
    { text: "Huidige stand", value: "WaterMeasurementm3", sortable: false },
    { text: "Vervangen ?", value: "NeedsToBeReplaced", sortable: false },
  ];

  async GetLastMeasurement(): Promise<void> {
    if (this.item && this.item !== null && this.item.LotNumber !== null) {
      this.item.WaterMeasurementm3Prev = await this.MeasurementService.GetMeasurementForLot(this.item);
    }
  }

  private handle = 0;
  GetCurrentMeasurement(): void {
    if (this.handle > 0) {
      clearTimeout(this.handle);
      handle = 0;
    }
    var handle = setTimeout(async () => {
      if (this.item && this.item !== null && this.item.LotNumber !== null && !isNaN(this.item.LotNumber)) {
        this.CurrentMeasurement = (await this.MeasurementService.GetCurrentMeasurementForLot(this.item)) ?? 0;
      }
      this.handle = 0;
    }, 500);
  }

  async SaveMeasurement(): Promise<void> {
    await this.OnlySave();

    var mtr = new WaterMeasurement();
    mtr.LotNumber = null;
    mtr.WaterMeasurementm3Prev = null;
    mtr.WaterMeasurementm3 = null;
    mtr.NeedsToBeReplaced = true;
    this.items.unshift(mtr);
    this.item = mtr;
    this.$refs.LotNumber.focus();
  }

  async OnlySave(): Promise<void> {
    this.$refs.WatermeasurementCard.loading = true;
    try {
      await this.MeasurementService.Upsert(this.item);
      this.InError = false;
    } catch (error) {
      this.InError = true;
    }
    this.$refs.WatermeasurementCard.loading = false;
  }

  RowClick(item: WaterMeasurement): void {
    this.item = item;
  }
}
