export class HistoralEntity {
  Created!: Date;
  End!: Date | string | null;
  LastModified!: Date;
  LastModifiedBy!: string;
  Start!: Date | string | null;
}

export class Plu extends HistoralEntity {
  Id = "";
  InkoopPrijs = 0;
  Name = "";
  PluNummer = 0;
  VerkoopPrijs = 0;
}

export class WeekOmzetRegel {
  Aantal = 0;
  Id = "";
  Jaar = 0;
  Plu!: Plu;
  PluNummer = 0;
  PluNaam = "";
  PluId = "";
  Prijs = 0;
  WeekNummer = 0;
  WeekOmzetStatus = 0;
  Totaal = 0;
}
