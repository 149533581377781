
import { BankStatementLedgerAssignment } from "../Models/BankStatementLedgerAssignment";
import { Ledger } from "../Models/Ledger";
import BookkeepingService from "../services/Bookkeepingservice";
import { Component, Vue, Watch } from "vue-property-decorator";
import LedgerList from "../components/LedgerList.vue";

@Component({
  components: { LedgerList },
})
export default class RegisterLedgerTransactions extends Vue {
  private BookingYear: number | undefined;
  private LedgerCode: string | undefined;

  bookKeepingService = new BookkeepingService();
  Statements = new Array<BankStatementLedgerAssignment>();
  Ledgers = new Array<Ledger>();

  ledgerFilter = "";
  LedgerListVisible = false;
  LongList = false;

  get InCrudRole(): boolean {
    return !this.$msal.IsInRole(this.$store.getters.user, "Bookkeeping.CRUD");
  }

  formatDate(date: Date): string {
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }

  async mounted(): Promise<void> {
    this.Ledgers = await this.bookKeepingService.Ledgers();
    this.BookingYear = parseInt(this.$route.params.BookingYear);
    this.LedgerCode = this.$route.params["LedgerCode"];

    if (!this.LedgerCode) {
      this.Statements = await this.bookKeepingService.GetUnassignedBankstatements();
    } else {
      var year = this.BookingYear === undefined ? (new Date().getFullYear() as number) : this.BookingYear;
      var rv = await this.bookKeepingService.BankStatementsLedgerAssignmentsForLedger(this.LedgerCode, year);
      this.Statements = rv;
    }
  }

  closeLedgerList(arg: boolean): void {
    this.LedgerListVisible = arg;
  }

  SetHoverState(record: BankStatementLedgerAssignment, state: boolean): void {
    record.RemarkHover = state;
  }

  CommitLedger(line: BankStatementLedgerAssignment): void {
    var item = this.Ledgers.find((e) => e.Code === line.BankStatementLedgerCode);
    if (item) {
      line.BankStatementLedgerID = item.Id;
      line.BankStatementLedgerName = item.Name;
      this.bookKeepingService.UpdateBankStatement(line);
    }
  }

  resetFilter(): void {
    this.ledgerFilter = "";
    this.LedgerListVisible = true;
  }

  ledgerKeyPress(event: KeyboardEvent, line: BankStatementLedgerAssignment): void {
    if (event.key == "s") {
      line.BankStatementLedgerID = line.SuggestedLedgerID;
      line.BankStatementLedgerCode = line.SuggestedLedgerCode;
      line.BankStatementLedgerName = line.SuggestedLedgerName;
      event.preventDefault();
      this.CommitLedger(line);
    } else {
      var filterstr = "";
      if (line.BankStatementLedgerCode) {
        filterstr = `${line.BankStatementLedgerCode}${event.key}`;
      } else {
        filterstr = event.key;
      }

      this.ledgerFilter = filterstr;
    }
  }
}
