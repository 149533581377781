
import vue from "vue";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import MemberService from "../services/MemberService";
import Person from "../Models/Person";
import Utility from "../lib/DateTimeFormatter";

@Component
export default class MemberInformation extends Vue {
  @Prop() private MemberId!: string;

  private memberService: MemberService | null = null;
  private CurrentPerson: Person | null = null;
  private TimeOutHandle = 0;
  private utility = new Utility();

  @Watch("CurrentPerson", { deep: true })
  async onPropertyChanged(oldValue: Person, newValue: Person): Promise<void> {
    if (!oldValue || !oldValue.Id || oldValue.Id === "") return;
    if (!newValue || !newValue.Id || newValue.Id === "") return;

    var ls = new Person();
    ls.Id = newValue.Id;
    ls.Start = newValue.Start;
    ls.IntakeDoneAt = newValue.IntakeDoneAt;
    ls.NumberOfChildren = newValue.NumberOfChildren;
    ls.NamesOfChildren = newValue.NamesOfChildren;
    ls.BirthDatesOfChildren = newValue.BirthDatesOfChildren;
    ls.BuddyDirector = newValue.BuddyDirector;
    ls.AdditionInfoUpdate = true;

    if (this.TimeOutHandle > 0) {
      clearTimeout(this.TimeOutHandle);
    }

    this.TimeOutHandle = setTimeout(async (): Promise<void> => {
      await this.memberService.UpdateMember(ls);
    }, 1000);
  }
  @Watch("MemberId")
  async onMemberIdChanged(): Promise<void> {
    console.log("trip");
    this.CurrentPerson = await this.memberService.GetMember(this.MemberId);
  }

  async mounted(): Promise<void> {
    this.memberService = new MemberService();
    if (this.MemberId) {
      this.onMemberIdChanged();
    }
  }
}
