
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import KassaladeOmzet from "../components/KassaladeOmzet.vue";
import VerkochteArtikelen from "../components/VerkochteArtikelen.vue";
import PluCard from "../components/PluCard.vue";

@Component({ components: { KassaladeOmzet, VerkochteArtikelen, PluCard } })
export default class SalesRegistry extends Vue {
  @Prop() private msg!: string;

  private Weeknummer = 1;
  private SalesState = 1;
  private Weeknummers = new Array<number>();
  CurrentWeek(): number {
    const todaydate = new Date();
    const oneJan = new Date(todaydate.getFullYear(), 0, 1);
    const numberOfDays = Math.floor((todaydate.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000));
    return Math.ceil((todaydate.getDay() + 1 + numberOfDays) / 7);
  }

  ChangeWeekState(state: number): void {
    this.SalesState = state;
  }

  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  get IsInRole(): boolean {
    return this.$msal.IsInRole(this.$store.getters.user, "CashRegister.CRU");
  }

  private initPage(): void {
    for (let index = 1; index <= 52; index++) {
      this.Weeknummers.push(index);
    }

    this.Weeknummer = this.Weeknummers[this.CurrentWeek()];
  }

  async mounted(): Promise<void> {
    var response = await this.$msal.SilentLogon();
    this.$store.dispatch("UserLoggedIn", response);
    if (!this.IsInRole) {
      this.$router.push("/home");
    }
    setTimeout(() => {
      this.initPage();
    }, 1000);
  }
}
