
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { InvoiceOverview } from "../Models/InvoiceOverview";
import MemberService from "../services/MemberService";

@Component
export default class InvoiceCard extends Vue {
  @Prop() private PersonId!: string;

  private headers = [
    { text: "Factuur", align: "start", value: "PaymentReference" },
    {
      text: "Vervaldatum",
      align: "start",
      value: "DueDate",
    },
    {
      text: "Bedrag",
      align: "start",
      value: "Amount",
    },
    {
      text: "reeds betaald",
      align: "start",
      value: "Sumofbankaccount",
    },
    { text: "Afgerond", align: "start", value: "IsClosed" },
    { text: "Handmatig gesloten", align: "start", value: "ManuallyClosed" },
    { text: "Reden", align: "start", value: "ReasonForManualClose" },
  ];

  private invoices: InvoiceOverview[] = [];

  private memberService = new MemberService();

  formatDate(date: Date | null): string | null {
    if (date === null) return null;
    return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 10 ? "0" : ""}${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }

  async mounted(): Promise<void> {
    this.invoices = await this.memberService.GetInvoiceOverview(this.PersonId);
  }
}
