
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Person extends Vue {
  @Prop() private PersonId!: string;

  date: Date | null = new Date();

  formattedDate(): string {
    if (this.date === null) return "";

    return this.formatDate(this.date) ?? "";
  }

  formatDate(date: Date | null): string | null {
    if (date === null) return null;
    return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}-${date.getMonth() < 9 ? "0" : ""}${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }
}
