import { AxiosResponse } from "axios";

import HttpClient from "./HttpClient";
import FileInformation from "../Models/FileInformation";

export default class FileService {
  httpClient = new HttpClient("File");

  async UploadFile(
    formData: FormData,
    memberId: string | null,
    lotId: string | null,
    fileType: string
  ): Promise<AxiosResponse<void>> {
    let requestUri = "";

    if (fileType === "Tuin") {
      if (!lotId) throw new Error("LotId is required for Tuin");
      requestUri += lotId;
    } else {
      if (!memberId) throw new Error("MemberId is required for other file types");
      requestUri += memberId;
    }

    requestUri += `/SubmitCaseFile/${fileType}`;

    return await this.httpClient.Upload(requestUri, formData);
  }

  async RemoveFile(FileToRemove: string, MemberId: string): Promise<void> {
    await this.httpClient.Delete(`file`, "", "filePath=" + FileToRemove);
  }

  async GetLotFiles(lotId: string): Promise<Array<FileInformation> | null> {
    const response = await this.httpClient.Get<Array<FileInformation> | null>(`Lot/${lotId}`);
    return response ? response : null;
  }

  async GetMemberFiles(memberId: string): Promise<Array<FileInformation> | null> {
    const response = await this.httpClient.Get<Array<FileInformation> | null>(`Member/${memberId}`);
    return response ? response : null;
  }
}
