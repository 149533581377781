export default class Person {
  Id = "";
  Name = "";
  DateOfBirth: Date | null = null;
  Address = "";
  PostalCode = "";
  City = "";
  PrimaryPhoneNumber = "";
  SecondaryPhoneNumber = "";
  Start: Date | null = null;
  End: Date | null = null;
  MailAddress = "";
  OnLotWaitingList = false;
  PartnerId: string | null = null;
  IntakeDoneAt: Date | null = null;
  NumberOfChildren = 0;
  NamesOfChildren = "";
  BirthDatesOfChildren = "";
  Profession = "";
  AdditionInfoUpdate: boolean | null = null;
  BuddyDirector = "";
  IntressedForALotInTheRange = 0;
}
