
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Vue } from "vue-property-decorator";

import invPreference from "../Models/InvoicePreference";
import MemberService from "../services/MemberService";

@Component
export default class InvoicePreferenceView extends Vue {
  private currentRcd = new invPreference();
  private memberService = new MemberService();
  private InformationSaved = false;

  private emailRules = [
    (v: string) => (!!v && this.currentRcd.SendByMail) || "E-mail is verplicht",
    (v: string) => /.+@.+\..+/.test(v) || "Dit is geen geldig email adres",
  ];

  private requiredRule = [(v: string) => (!!v && this.currentRcd.SendByMail === false) || "Dit veld is verplicht"];

  async SendPreference(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((this.$refs.form as any).validate()) {
      const response = await this.memberService.PostInvoicePrefernce(this.currentRcd);
      if (response.status === 200) {
        this.InformationSaved = true;
      }
    }
  }

  mounted(): void {
    this.currentRcd.SendByMail = true;
    const lotnr: string | null = this.$route.query["LotNr"] as string | null;
    const mailAddress: string | null = this.$route.query["MailAddress"] as string | null;
    const MemberId: string | null = this.$route.query["MemberId"] as string | null;
    const hash: string | null = this.$route.query["hash"] as string | null;

    this.currentRcd.Lot = lotnr ?? "";
    this.currentRcd.Email = mailAddress;
    this.currentRcd.MemberId = MemberId;
    this.currentRcd.Hash = hash;
  }
}
