
import vue from "vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Lot } from "../Models/Lot";
import { v4 as uuidv4 } from "uuid";
import { LotSalesMessage } from "../Models/LotSalesMessage";
import { BuyersCondition } from "../Models/BuyersCondition";
import { LotSale } from "../Models/LotSale";

import LotsForSale from "../components/LotsForSale.vue";
import ChangeOwnership from "../components/ChangeOwnership.vue";
import LotService from "../services/lotService";

import { watch } from "vue";
import FileService from "../services/FileService";
import FileInformation from "../Models/FileInformation";

@Component({
  components: {
    LotsForSale,
    ChangeOwnership,
  },
})
export default class House extends Vue {
  private tab: unknown = null;
  private isSend = false;
  private selectedLot: LotSale | null = null;
  private LotSalesMessage: LotSalesMessage = new LotSalesMessage();

  private lotService!: LotService;
  private fileService = new FileService();
  private registeredPhotos: FileInformation[] | null = null;
  private TimeOutHandle = 0;
  private PriceRange = [4000, 8000];
  private NumberOfMembersToOffer = 0;

  private tickLabels = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"];

  host = `${vue.prototype.$Constants.ApiAddress}api`;

  private async SetSelectedHouse(lot: LotSale): Promise<void> {
    this.selectedLot = lot;

    this.lotService.GetLotSalesMessage(lot.Id).then(async (result) => {
      if (!result) {
        result = new LotSalesMessage();
        result.Id = uuidv4();
        result.LotId = lot.Id;
        result.BuyersConditions = [];
        result.HasBeenOffered = false;
        result.MarketValue = this.selectedLot.MarketValue;
        result.Subject = "Aanschaf huisje " + this.selectedLot.LotId;
      }
      this.LotSalesMessage = result;
      this.PriceRange = [this.LotSalesMessage.MarketValue - 3000, this.LotSalesMessage.MarketValue + 3000];
      await this.GetSalesPhotos();
    });
  }

  private async GetSalesPhotos(): Promise<void> {
    var files = await this.fileService.GetLotFiles(this.LotSalesMessage.LotId);
    this.registeredPhotos = files;
  }

  GotoOfferTab(): void {
    this.tab = 1;

    console.log(this.selectedLot);
  }

  async SendOffering(): Promise<void> {
    this.LotSalesMessage.HasBeenOffered = true;
    this.isSend = true;
    await this.lotService.UpdateLotSalesMessage(this.LotSalesMessage);
    await this.lotService.SendOffering(this.LotSalesMessage.LotId);
    this.isSend = true;
    setTimeout(() => {
      this.isSend = false;
      this.tab = 1;
    }, 1000);
  }

  AddCondition(): void {
    this.LotSalesMessage.BuyersConditions.push(
      new BuyersCondition({ Id: uuidv4(), Condition: "", ConditionDeposit: 0, IsNew: true })
    );
  }

  @Watch("PriceRange", { deep: true })
  async OnPriceRangeChange(newValue: number[]): Promise<void> {
    if (this.TimeOutHandle > 0) {
      clearTimeout(this.TimeOutHandle);
    }

    this.TimeOutHandle = setTimeout(async (): Promise<void> => {
      var result = await this.lotService.GetWaitListMemberCount(this.LotSalesMessage.LotId, newValue[0], newValue[1]);
      this.NumberOfMembersToOffer = result;
    }, 1000);
  }

  @Watch("LotSalesMessage", { deep: true })
  async OnChange(newValue: LotSalesMessage): Promise<void> {
    if (this.TimeOutHandle > 0) {
      clearTimeout(this.TimeOutHandle);
    }

    this.TimeOutHandle = setTimeout(async (): Promise<void> => {
      newValue.OffertedToWaitlistMembersWithMinimumBudget = this.PriceRange[0];
      newValue.OffertedToWaitlistMembersWithMaximumBudget = this.PriceRange[1];
      this.lotService.UpdateLotSalesMessage(newValue);
      if (this.LotSalesMessage.Images != null) {
        var counter = (this.registeredPhotos?.length || 0) + 1;

        await Promise.all(
          this.LotSalesMessage.Images.map(async (file) => {
            console.log(file);
            if (file.Uploaded) return;
            var name = `Tuinhuisje foto ${counter++}` + `.${file.name.split(".").pop()}`;
            var fileToSend = new File([file], name, { type: file.type });
            var formData = new FormData();
            formData.append("formFile", fileToSend);
            await this.fileService.UploadFile(formData, null, this.LotSalesMessage.LotId, "Tuin");
          })
        );
        await this.GetSalesPhotos();
      }
      newValue.BuyersConditions.forEach((element) => {
        if (element.IsNew) {
          element.IsNew = false;
        }
      });
    }, 1000);
  }

  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  async mounted(): Promise<void> {
    try {
      var response = await this.$msal.SilentLogon();
      this.$store.dispatch("UserLoggedIn", response);
      const user = this.$store.getters.user;
      this.lotService = new LotService();
      if (user === null) {
        this.$router.push("/home");
      }
    } catch (error) {
      this.$router.push("/home");
    }
  }
}
