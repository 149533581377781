
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import BookingDashboard from "../components/BookingDashboard.vue";

@Component({
  components: {
    BookingDashboard,
  },
})
export default class Bookkeeping extends Vue {
  @Prop() private msg!: string;

  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  get IsInRole(): boolean {
    return this.$msal.IsInRole(this.$store.getters.user, "Bookkeeping.Read");
  }

  // @Watch("IsAuthenticated")
  // onPropertyChanged(value: boolean, oldValue: boolean): void {}

  async mounted(): Promise<void> {
    if (!this.IsAuthenticated) {
      try {
        var response = await this.$msal.login();
        this.$store.dispatch("UserLoggedIn", response);
        if (!this.IsInRole) this.$router.push("/Overzicht");
      } catch (error) {
        this.$router.push("/home");
      }
    }
  }
}
