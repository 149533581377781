
import { AuthenticationResult } from "@azure/msal-common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Home extends Vue {
  @Prop() private msg!: string;

  private Authenticated = false;

  get IsAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  // @Watch("IsAuthenticated")
  // onPropertyChanged(value: boolean, oldValue: boolean): void {
  //   if (value === true) {
  //     this.$router.push("/Kaart");
  //   }
  // }
  async mounted(): Promise<void> {
    if (this.$store.getters.isAuthenticated) {
      this.$router.push("Overzicht");
    } else {
      var resp = await this.$msal.SilentLogon();
      if (resp == null) return;
      this.processLogin(resp);
    }
  }

  private processLogin(x: AuthenticationResult) {
    this.Authenticated = true;
    this.$store.dispatch("UserLoggedIn", x);
    if (this.$route.query.origin) {
      var destination = this.$route.query.origin as string;
      this.$router.push(destination);

      return;
    }
    this.$router.push("/Overzicht");
  }

  login(): void {
    this.$msal
      .login()
      .then((x: AuthenticationResult) => {
        this.processLogin(x);
      })
      .catch((x: Error) => {
        console.log("error", x);
      });
  }
}
