export class MemberOverview {
  Address!: string;
  City!: string;
  DateOfBirth!: string | null;
  LotId!: string;
  LotNumber!: string;
  MailAddress!: string;
  MemberId!: string;
  MemberSince!: string | null;
  Name!: string;
  PostalCode!: string;
  PrimaryPhoneNumber!: string;
  BuddyDirector!: string;
  IntakeDoneAt!: string | null;
}
