
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import MemberService from "../services/MemberService";
import FileService from "../services/FileService";

@Component
export default class UploadContainer extends Vue {
  private memberService = new MemberService();
  private fileService = new FileService();
  private fileList: Array<File> = [];
  private fileName: string | null = null;
  private IsLoading = false;

  private DocumentType = ["Tuin", "Lid"];

  @Prop() private MemberId!: string;
  @Prop() private LotId!: string;

  @Emit()
  FilesUploaded(): void {
    return;
  }

  AddToFileList(e: Event): void {
    console.log(e);
    if (e) {
      var file = e as unknown as File;
      if (file) {
        file.Uploaded = false;
        console.log(file);
        this.fileList.push(file);
      }
      this.fileName = null;
    }
  }

  async UploadFiles(): Promise<void> {
    this.IsLoading = true;

    await Promise.all(
      this.fileList.map(async (file) => {
        if (file.Uploaded) return;
        var formData = new FormData();
        formData.append("formFile", file);
        const response = await this.fileService.UploadFile(formData, this.MemberId, this.LotId, file.LotFile);
        if (response.status === 200) {
          file.Uploaded = true;
        }
      })
    );
    this.fileList = new Array<File>();
    this.IsLoading = false;
    this.$emit("FilesUploaded");
  }

  dragFile(e: DragEvent): void {
    if (e.dataTransfer) {
      for (let index = 0; index < e.dataTransfer.files.length; index++) {
        var file = e.dataTransfer.files[index];
        file.Uploaded = false;
        this.fileList.push(file);
      }
    }
  }

  async mounted(): Promise<void> {
    return;
  }
}

declare global {
  interface File {
    LotFile: string;
    Uploaded: boolean;
  }
}
