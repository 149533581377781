
import vue from "vue";
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { Ledger } from "../Models/Ledger";

@Component
export default class LedgerList extends Vue {
  @Prop() Ledgers!: Array<Ledger>;
  @Prop() Filter!: string;

  get LedgerCollection(): Array<Ledger> {
    var filter = this.Filter;

    return this.Ledgers.filter((e) => {
      return e.Code.startsWith(filter);
    });
  }

  @Emit()
  CloseLegerList(): boolean {
    return false;
  }
}
