
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vue from "vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { MemberOverview } from "../Models/MemberOverview";
import Person from "../Models/Person";
import MemberService from "../services/MemberService";
import dtFormatter from "../lib/DateTimeFormatter";
import Role from "../Models/Role";

@Component
export default class MembersDataTable extends Vue {
  searchMembers = "";
  searchNonMembers = "";
  MembersWithLots = false;
  private allRoles: Role[] = [];
  private allBuddies: string[] = [];
  private Ages: number[] = [0];
  private MinAge = 18;
  private MaxAge = 99;
  private selectedRoles = [];
  private AllHouses = true;
  private selectedBuddy: string | null = null;
  private selectedMember: Person | null = null;
  private currentTab: unknown = "Members";
  private Utility = new dtFormatter();

  Headers = [
    {
      text: "Tuin nr",
      align: "start",
      value: "LotNumber",
    },
    { text: "Naam", value: "Name" },
    { text: "Email adres", value: "MailAddress", sortable: false, align: " d-none d-lg-table-cell", filterable: false },
    {
      text: "Telefoonnummer",
      value: "PrimaryPhoneNumber",
      sortable: false,
      align: " d-none d-lg-table-cell",
      filterable: false,
    },
    {
      text: "Buddy bestuurder",
      value: "BuddyDirector",
      sortable: true,
      align: " d-none d-lg-table-cell",
      filterable: true,
    },
  ];

  HeadersNoLot = [
    {
      text: "Volgnr",
      align: "start",
      value: "LotNumber",
    },
    { text: "Naam", value: "Name" },
    { text: "MailAddress", value: "MailAddress", sortable: false, align: " d-none d-lg-table-cell", filterable: false },
    {
      text: "Telefoonnummer",
      value: "PrimaryPhoneNumber",
      sortable: false,
      align: " d-none d-lg-table-cell",
      filterable: false,
    },
    {
      text: "MemberSince",
      value: "MemberSince",
      sortable: true,
      align: " d-none d-lg-table-cell",
      filterable: true,
    },
    {
      text: "Intake gedaan op:",
      value: "IntakeDoneAt",
      sortable: true,
      align: " d-none d-lg-table-cell",
      filterable: true,
    },
  ];

  sortBy = "LotNumber";
  itemsPerPage = 50;
  private MembersWithALot = new Array<MemberOverview>();
  private MembersWithNoLot = new Array<MemberOverview>();

  private memberService: MemberService | null = null;

  async mounted(): Promise<void> {
    if (this.$route.params.LotMembers) {
      if (this.$route.params.LotMembers === "true") {
        this.currentTab = "Members";
      } else {
        this.currentTab = "Waitlist";
      }
    } else {
      this.currentTab = "Members";
    }

    this.memberService = new MemberService();

    this.allRoles = await this.memberService.GetAllRoles();
    this.allBuddies = await this.memberService.GetAllBuddies();
    this.UpdateList();
    for (let index = 18; index < 100; index++) {
      this.Ages.push(index);
    }
  }

  async UpdateList(): Promise<void> {
    if (this.memberService) {
      this.MembersWithALot = await this.memberService.GetMembers(
        true,
        this.selectedRoles,
        this.MinAge,
        this.MaxAge,
        this.AllHouses,
        this.selectedBuddy
      );
      this.MembersWithNoLot = await this.memberService.GetMembers(
        false,
        this.selectedRoles,
        this.MinAge,
        this.MaxAge,
        this.AllHouses,
        this.selectedBuddy
      );
    }
  }

  async FilterByOwnership(): Promise<void> {
    if (this.memberService) {
      this.AllHouses = !this.AllHouses;
      this.UpdateList();
    }
  }

  async FilterMembersByRole(): Promise<void> {
    this.UpdateList();
  }

  async RowSelect(row: MemberOverview): Promise<void> {
    this.$router.push({
      name: "Kaart",
      params: { MemberId: row.MemberId, LotNumber: row.LotNumber, LotId: row.LotId },
    });
  }

  async RegisterNewMember(): Promise<void> {
    if (!this.memberService) {
      return;
    }
    var memberId = await this.memberService.CreateNewMember();
    this.$router.push({
      name: "Kaart",
      params: { MemberId: memberId },
    });
  }
}
