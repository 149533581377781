import { ReportRow } from "./ReportRow";

export class LedgerResult extends ReportRow {
  constructor(init?: Partial<LedgerResult>) {
    super();
    Object.assign(this, init);
  }

  DebetOrCredit!: boolean;
}
